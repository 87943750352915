import React from 'react';
import { ColumnsType } from 'antd/es/table';

import NameImage from '../client/NameImage';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { addDecimalPoints, generateKey } from '../../utils/candidate';
import styled from 'styled-components';
import { Table } from 'antd';
import { Payment } from '../../types';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE } from '../../constants/roles';
interface PaymentsTableProps {
  payments: Payment[];
}
const StyledTable = styled(Table)`
  & .ant-table-tbody {
    & > tr {
      & > td {
        padding: 1.5rem 0.5rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          & button {
            margin-left: auto;
          }
        }
      }
      &:hover {
        & > td {
          background: #ffffff;
        }
      }
      &:last-child > td{
      border-bottom: none;
      }
    }
  }
`;
const PayButton = styled.div`
  width: 100%;
  max-width: 85px;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  font-size: 10px;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  margin-left: auto;
  text-transform: uppercase;
`;
const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1.67;
  color: #061c2e;
  margin: 0;
`;
const Price = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.43;
  color: #061c2e;
  margin: 0;
`;
const PaymentsTable = ({ payments }: PaymentsTableProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  const columns: ColumnsType<Payment> = [
    {
      title: '',
      width: 250,
      key: 'candidate',
      dataIndex: 'name',
      render: (_, record) => (
        <NameImage
          title={record?.candidate}
          color={record?.company?.color}
          image={''}
          description={record?.position}
        />
      ),
    },
    {
      title: '',
      width: 250,
      key: 'department',
      dataIndex: 'department',
      render: (_, record) => (
        <NameImage
          title={isClient ? record?.recruiter?.name : record?.company?.name}
          image={isClient ? record?.recruiter?.image : record?.company?.image}
          description={''}
          color={record?.recruiter?.color}
        />
      ),
    },
    {
      title: '',
      width: 100,
      key: 'registered_at',
      dataIndex: 'skills',
      render: (registered_at, record) => (
        <Price>€ {addDecimalPoints(record.amount.toString())}</Price>
      ),
    },
    {
      title: '',
      width: 250,
      key: 'jobs',
      dataIndex: 'salary',
      render: (_, record) => (
        <div>
          <Text>
            {t('PAYMENT_DATE')}:{' '}
            {format(new Date(record.payment_date), 'MMMM d, yyyy')}
          </Text>
          <Text>
            {t('CANDIDATE_START_DATE')}:{' '}
            {format(new Date(record.candidate_start_date), 'MMMM d, yyyy')}
          </Text>
          <Text>
            {t('GUARANTEE_PERIOD')}: {record.guarantee_period}{' '}
            {t(record.guarantee_period < 2 ? 'MONTH' : 'MONTHS')}
          </Text>
        </div>
      ),
    },
    {
      title: '',
      width: 100,
      key: 'action',
      dataIndex: 'id',
      render: (id, record) =>
        record.paid ? <PayButton>{t('PAID')}</PayButton> : '',
    },
  ];
  return (
    <StyledTable
      locale={{ emptyText: ' ' }}
      // @ts-ignore
      columns={columns}
      dataSource={payments}
      showHeader={false}
      scroll={{ x: 950 }}
      pagination={false}
      rowKey={() => generateKey(5)}
    />
  );
};

export default PaymentsTable;
