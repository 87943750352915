import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { User } from "../types";

// Add this type declaration at the top of your file
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const useIntercomUpdate = (user: User | null) => {
  const { email, name, id, role, has_default_tier } = user || {};

  // I guess this dependency triggers an update if the URL changes
  const location = useLocation();
  useEffect(() => {
    if (!id)
      return;

    // Update Google Tag Manager dataLayer
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': "user_authenticated_event",
        'email': email,
        'name': name,
        'user_id': id,
        'user_role': role,
        'user_has_default_tier': role === 'recruiter' ? has_default_tier : false,
      });
    }

  }, [location, email, name, id, role, has_default_tier]);
};

export default useIntercomUpdate;
