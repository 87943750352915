import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Upload } from 'antd';
import FormItem from './FormItem';
import { maxLengthRule, requiredRule } from '../../utils/validationRules';
import Button from '../../UI/buttons/Button';
import DateTimePicker from '../../UI/inputs/DateTimePicker';
import Input from '../../UI/inputs/Input';
import Textarea from '../../UI/inputs/Textarea';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { RcFile } from 'antd/lib/upload';
import EmploymentTypeSelectFormSection from './shared/employment-types/EmploymentTypeSelectFormSection';

interface CandidateOfferFormProps {
  file: string;
  isResend: boolean;
  loading: boolean;
  setFile: React.Dispatch<React.SetStateAction<string>>;
  onFinish: (values: any) => void;
  onReject: (text?: string) => void;
  form: any;
}

const FileUploader = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 100%;
  height: 2.9rem;
`;
const FileName = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 0 0.5rem 0 0 !important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const DeleteFileIcon = styled(DeleteIcon)`
  color: #eb5757;
  margin-left: 0.5rem;
  cursor: pointer;
`;
const UploadAction = styled.div`
  display: flex;
  align-items: center;
`;
const StyledUploader = styled(Upload)`
  display: flex;
  align-items: center;
  & .ant-upload-select {
    width: 100%;
    border: 1px solid #aebeca;
    border-radius: ${({ theme }) => theme.radius};
    height: 3rem;
    & .ant-upload {
      display: flex;
      & input[type='file'] {
        visibility: hidden;
        display: initial !important;
        width: 0;
      }
    }
  }
`;
const StyledButton = styled(Button)`
  & + button {
    margin-left: 1rem;
  }
  & span {
    display: block;
    text-align: center;
    width: 100%;
  }
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
const FormSubtitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  // color: #627d95;
  margin: 1.5rem 0;
`;
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
`;

const CandidateOfferForm = ({
  file,
  setFile,
  onFinish,
  form,
  onReject,
  isResend,
  loading,
}: CandidateOfferFormProps) => {
  const [t] = useTranslation();
  const [error, setError] = useState(false);
  const [comment, setComment] = useState('');

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    return true;
  };
  const handleReject = () => {
    if (comment) {
      setError(false);
      onReject(comment);
    } else {
      setError(true);
    }
  };
  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ offer_doc: undefined });
  };
  useEffect(() => {
    setComment(form.getFieldValue('comment'));
  }, [form]);
  return (
    <Form
      form={form}
      layout="vertical"
      name="add-client"
      onFinish={(values: any) => {
        setError(false);
        onFinish(values);
      }}
      scrollToFirstError={true}
    >
      {isResend ?
        <FormSubtitle>{t('EDIT_OFFER')}</FormSubtitle> :
        <FormSubtitle>{t('Offer details')}</FormSubtitle>}

      <Row gutter={16}>
        <Col xs={{ span: 24 }}>
          <FormItem
            label={t('UPLOAD_OFFER')}
            name="offer_doc"
            rules={requiredRule(t)}
          >
            <StyledUploader
              name="file"
              showUploadList={false}
              beforeUpload={handleUploadFile}
              customRequest={() => void 0}
            >
              <FileUploader>
                <FileName>{file}</FileName>
                <UploadAction>
                  <Button type={'default'} size={'small'}>
                    {t('CHOOSE_FILE')}
                  </Button>
                  {file && <DeleteFileIcon onClick={handleFileDelete} />}
                </UploadAction>
              </FileUploader>
            </StyledUploader>
          </FormItem>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <FormItem
            label={t('START_DATE')}
            name="start_date"
            rules={requiredRule(t)}
          >
            <DateTimePicker
              format={(value: any) => {
                return value.format('MMM D, y');
              }}
              showTime={false}
              showToday={false}
            />
          </FormItem>
        </Col>

        <Col xs={{ span: 24 }}>
          <FormItem
            label={t('ROLE')}
            name="role"
            rules={[...requiredRule(t), ...maxLengthRule(32, t)]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
      </Row>
      <FormSubtitle>{t('Compensation')}</FormSubtitle>
      <EmploymentTypeSelectFormSection
        name={['compensation']}
        getSelectedEmploymentType={() => form.getFieldValue(['compensation', 'type', 'id'])}
        displayContext="offer"
      />
      <Row gutter={16}>
        <Col xs={{ span: 24 }}>
          <FormItem
            label={t('OTHER_BENEFITS')}
            name="other_benefits"
            rules={[...maxLengthRule(255, t)]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
        <Col xs={{ span: 24 }}>
          <FormItem label={t('COMMENT')} name="comment">
            <Textarea
              autoSize={{ minRows: 5 }}
              onChange={(e: any) => {
                form.setFieldsValue({ comment: e.target.value });
                setComment(e.target.value);
              }}
              value={comment}
            />
            {error && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">{t('REQUIRED_FIELD')}</div>
              </div>
            )}
          </FormItem>
        </Col>
      </Row>
      <ActionWrapper>
        <StyledButton type="primary" htmlType="submit" size="large" loading={loading}>
          {t(isResend ? 'RESEND_OFFER' : 'SEND_OFFER')}
        </StyledButton>
        <StyledButton
          danger
          size="large"
          type="default"
          onClick={() => {
            handleReject();
          }}
        >
          {t('REJECT_CANDIDATE')}
        </StyledButton>
      </ActionWrapper>
    </Form>
  );
};

export default CandidateOfferForm;
