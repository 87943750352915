import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row, message } from 'antd';
import { storeJobId } from '../../../../utils/localStorage';

import BackButton from '../../../../UI/buttons/BackButton';
import { CLIENT, JOBS, REGISTER } from '../../../../constants/routes';
import { Skill, Job, JobActivity } from '../../../../types';
import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  RECRUITER_ROLE,
} from '../../../../constants/roles';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ReactComponent as EmployeesIcon } from '../../../../icons/employees.svg';
import { ReactComponent as CheckIcon } from '../../../../icons/check-icon.svg';
import { ReactComponent as DepartmentIcon } from '../../../../icons/department.svg';
import { ReactComponent as LocationIcon } from '../../../../icons/location.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as SalaryIcon } from '../../../../icons/salary.svg';
import { ReactComponent as EyeInvisibleIcon } from '../../../../icons/eye-invisible.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/trash.svg';
import { ReactComponent as EyeIcon } from '../../../../icons/eye.svg';
import { ReactComponent as AddUserIcon } from '../../../../icons/add-user.svg';
import { ReactComponent as ChatIcon } from '../../../../icons/chat.svg';
import { ReactComponent as PdfIcon } from '../../../../icons/pdf.svg';
import { ReactComponent as PlusBorderedIcon } from '../../../../icons/plus-bordered.svg';
import {
  addToFavorites,
  deleteJob,
  publishJob,
  removeFromFavorites,
} from '../../../../api/jobs';
import { useAppSelector } from '../../../../store/hooks';
import {
  BannerWrapper,
  BannerOverlay,
  BannerContent,
  BannerHeader,
  HeaderTitle,
  BannerInfo,
  BannerLogo,
  BannerInfoText,
  JobTitle,
  JobAbout,
  SkillItem,
  SkillItemList,
  JobInfoItem,
  ActionButtons,
  StyledButton,
  SkillItemAlt,
} from './styles';
import useGetPdf from './hooks';
import Emoji from '../../../helper/Emoji';
import { relocationOptions } from "../../../../constants/relocations";
import UnpublishJobModal from "../../UnpublishJobModal";
import React, { useState } from "react";
import PointsLeft from "../../../recruiter/PointsLeft";
import JobActivityFeed from "../JobActivityFeed";
import TooltipPrompt from "../../../../UI/tootlip";
import getEmploymentTypeSummary from '../../../../utils/getEmploymentTypeSummary';
import useEmploymentTypes from '../../../../hooks/useEmploymentTypes';
import { useHistoryWithUrlBase } from '../../../../hooks/useHirePortContext';
import useHirePortTheme from '../../../../hooks/useHirePortTheme';

declare global {
  interface Window {
    $crisp: any;
  }
}

type BannerProps = {
  role?: string;
  onAction: React.Dispatch<React.SetStateAction<any>>;
  handleIntroduction: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  languages?: string[];
  activityFeed?: JobActivity[] | null;
  languageList: { [key: string]: string };
  job: Job | null;
  favorite?: boolean;
  onSetFavorite?: any;
  showBackButton: boolean;
}

const { confirm } = Modal;

const Banner = ({
  onAction,
  role,
  handleIntroduction,
  loading,
  languages,
  languageList,
  job,
  favorite,
  activityFeed,
  onSetFavorite,
  showBackButton,
}: BannerProps) => {
  const [t] = useTranslation();
  const theme = useHirePortTheme();
  const history = useHistoryWithUrlBase();
  const employmentTypes = useEmploymentTypes();

  const { user } = useAppSelector((state) => state.user);
  const { id } = useParams<{ id: string }>();
  const isClient = user?.role === CLIENT_ROLE;

  const defaultBackgroundImage = 'https://hireport-storage-eu-central-1.s3.amazonaws.com/media/jobs/6dbf4b4e-9c23-475c-a505-4695c7b63c42.jpg';
  const {
    actions: { getPdf },
  } = useGetPdf(id);

  const handleFavorite = () => {
    if (favorite) {
      removeFromFavorites(id).then((res) => console.log(res));
    } else {
      addToFavorites(id).then((res) => console.log(res));
    }
    favorite = !favorite;
    onSetFavorite(favorite);
  };

  const [isUnpublishModalOpen, setisUnpublishModalOpen] = useState<boolean>(false);

  const openIntercom = () => {
    // // @ts-ignore
    // if (window.Intercom !== undefined) {
    //   // @ts-ignore
    //   window.Intercom('showNewMessage', `I have a question about ${job?.title} from ${job?.company.name} `);
    // }

    if (window.$crisp) {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:open"]);
      window.$crisp.push(["set", "message:text", [`I have a question about the ${job?.title} position at ${job?.company.name}`]]);
    } else {
      console.warn("Crisp is not loaded yet");
    }
  }

  const onDelete = () => {
    confirm({
      title: `${t('DELETE_POPUP_TITLE')}?`,
      okText: t('CONFIRM_TITLE'),
      okType: 'danger',
      cancelText: t('REJECT_TITLE'),
      cancelButtonProps: {
        type: 'default',
      },
      onOk() {
        deleteJob(id).then(() => {
          message.error(t('JOB_IS_DELETED'), 5);
          history.push(JOBS);
        });
      },
    });
  };

  const onPublish = () => {
    publishJob(id).then((res) => {
      onAction((prev: any) => ({ ...prev, status: res.data.status }));
    });
  };

  const onUnpublish = () => {
    setisUnpublishModalOpen(true);
  };

  if (!job) {
    return (
      <BannerWrapper
        style={{
          backgroundImage: `url('')`,
          backgroundColor: 'inherit',
        }}
      >
        <BannerOverlay />
      </BannerWrapper>
    );
  }

  const {
    bg_image,
    status,
    title,
    short_description,
    number_of_views,
    can_be_unpublished,
    key_skills,
    min_certificates,
    department,
    is_visa_available,
    working_type,
    relocation,
  } = job;

  const relocationObject = relocationOptions.find((option) => option.id === relocation);
  const logo = job?.company?.image;
  const companyName = job?.company?.name;
  const employees = job?.company?.number_of_employees;
  const location = job?.company?.city?.name;
  const published = status === 'published';


  let workingType = t('REMOTE');
  if (working_type === 'full_office') {
    workingType = t('OFFICE');
  } else if (working_type === 'hybrid') {
    workingType = t('WORKING_TYPE_HYBRID');
  }


  const employmentTypeSummary = getEmploymentTypeSummary(t, job.employment_type, employmentTypes);

  return (
    <BannerWrapper
      style={{
        backgroundImage: `url(${bg_image ? bg_image : defaultBackgroundImage}`,
        backgroundColor: bg_image ? '#fff' : 'inherit',
      }}
    >
      <BannerOverlay>
        <BannerContent>
          <BannerHeader>
            {showBackButton &&
              <>
                <BackButton onClick={() => history.push(`${JOBS}`)} />
                <HeaderTitle>{t('JOBS')}</HeaderTitle>
              </>
            }
          </BannerHeader>
          {!loading && (
            <BannerInfo>
              {logo && (
                <BannerLogo>
                  <img src={logo} alt="Logo" />
                </BannerLogo>
              )}
              <BannerInfoText>
                <JobTitle>
                  <h2>
                    {title} {t('AT')} <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + job.company?.id}>{companyName}</Link></TooltipPrompt>
                  </h2>
                  {role === CLIENT_ROLE || role === ADMIN_ROLE ? (
                    <>
                      <span className="views">
                        {published ? (
                          <>
                            <EyeOutlined /> {number_of_views || '0'}
                          </>
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </span>
                    </>
                  ) : (
                    <Emoji
                      symbol={favorite ? '🔔' : '🔕'}
                      onClick={() => handleFavorite()}
                    />
                  )}
                </JobTitle>

                <JobAbout>{short_description}</JobAbout>
                {(!isClient && user) && (
                  <JobActivityFeed activityFeed={activityFeed} />
                )}

                <Row
                  gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                  justify="space-between"
                >
                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 5 }}>
                    <SkillItemList>
                      <SkillItem
                        style={{ backgroundColor: '#faf9e8' }}
                      >
                        {employmentTypeSummary && employmentTypeSummary.employmentType}
                      </SkillItem>
                    </SkillItemList>
                    <JobInfoItem>
                      <DepartmentIcon /> {department}
                    </JobInfoItem>
                    <JobInfoItem>
                      <LocationIcon /> {location}
                    </JobInfoItem>
                    {employmentTypeSummary && employmentTypeSummary.fields['banner'].map(({ value, secondaryLabel }, index) => (
                      <JobInfoItem>
                        {index === 0 ? <SalaryIcon /> : <PlusBorderedIcon />}
                        {value} {secondaryLabel}
                      </JobInfoItem>
                    ))}
                    {employees > 0 && (
                      <JobInfoItem>
                        <EmployeesIcon />
                        {`${employees} ${t('EMPLOYEES')}`}
                      </JobInfoItem>
                    )}
                  </Col>

                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
                    <SkillItemList>
                      {key_skills?.map((el: Skill) => (
                        <SkillItem
                          key={el.id}
                          style={{ backgroundColor: theme.lozengeColor }}
                        >
                          {el.name}
                        </SkillItem>
                      ))}
                    </SkillItemList>

                    <SkillItemList>
                      {min_certificates &&
                        min_certificates.map((certificate: any) => (
                          <SkillItem
                            key={certificate.id}
                            style={{ backgroundColor: '#fcedde' }}
                          >
                            {certificate.name}
                          </SkillItem>
                        ))}
                    </SkillItemList>

                    <SkillItemList>
                      {languages &&
                        languages.map((language) => (
                          <SkillItem
                            key={language}
                            style={{ backgroundColor: '#eff9f3' }}
                          >
                            {languageList[language]}
                          </SkillItem>
                        ))}
                    </SkillItemList>

                    <SkillItemList>
                      {workingType && (
                        <SkillItemAlt style={{ backgroundColor: '#f0ecf7' }}>{t(workingType)}</SkillItemAlt>
                      )}
                    </SkillItemList>

                    <SkillItemList>
                      {is_visa_available && <SkillItemAlt style={{ backgroundColor: '#f0ecf7' }}>Visa</SkillItemAlt>}
                      {relocationObject && <SkillItemAlt style={{ backgroundColor: '#f0ecf7' }}>{t(relocationObject.label)}</SkillItemAlt>}
                    </SkillItemList>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }}>
                    <ActionButtons>
                      {user?.role === CLIENT_ROLE && (
                        <>
                          <StyledButton
                            icon={<EditIcon />}
                            size="large"
                            type="primary"
                            onClick={() => {
                              history.push(
                                `${JOBS}/edit/${id}/company_info`,
                              )
                            }
                            }
                          >
                            {t('EDIT_TITLE')}
                          </StyledButton>
                          {/* hide by BB-2240
                          {(status === 'draft' || status === 'unpublished') && (
                            <>

                              <StyledButton
                                danger
                                icon={<DeleteIcon />}
                                size="large"
                                type="primary"
                                onClick={onDelete}
                              >
                                {t('DELETE_TITLE')}
                              </StyledButton>
                            </>
                          )}
                          */}
                          {(status === 'published' ||
                            status === 'under review' ||
                            status === 'upcoming') && (
                              <StyledButton
                                icon={<EyeInvisibleIcon />}
                                size="large"
                                type="primary"
                                onClick={onUnpublish}
                                disabled={!can_be_unpublished}
                              >
                                {t('UNPUBLISH_TITLE')}
                              </StyledButton>
                            )}
                          {(status === 'unpublished' || status === 'on_hold') && (
                            <StyledButton
                              icon={<EyeIcon />}
                              size="large"
                              type="primary"
                              onClick={onPublish}
                            >
                              {t('PUBLISH_TITLE')}
                            </StyledButton>
                          )}
                        </>
                      )}
                      {user?.role === ADMIN_ROLE && (
                        <>
                          <StyledButton
                            icon={<EditIcon />}
                            size="large"
                            type="primary"
                            onClick={() =>
                              history.push(
                                `${JOBS}/edit/${id}/company_info`,
                              )
                            }
                          >
                            {t('EDIT_TITLE')}
                          </StyledButton>
                          {(status === 'unpublished' || status === 'on_hold') && (
                            <StyledButton
                              icon={<EyeIcon />}
                              size="large"
                              type="primary"
                              onClick={onPublish}
                            >
                              {t('PUBLISH_TITLE')}
                            </StyledButton>
                          )}
                          {(status === 'draft' || status === 'unpublished') && (
                            <>
                              <StyledButton
                                danger
                                icon={<DeleteIcon />}
                                size="large"
                                type="primary"
                                onClick={onDelete}
                              >
                                {t('DELETE_TITLE')}
                              </StyledButton>
                            </>
                          )}
                          {status === 'under review' && (
                            <>
                              <StyledButton
                                icon={<EyeIcon />}
                                size="large"
                                type="primary"
                                onClick={onPublish}
                              >
                                {t('PUBLISH_TITLE')}
                              </StyledButton>
                              <StyledButton
                                danger
                                icon={<DeleteIcon />}
                                size="large"
                                type="primary"
                                onClick={onDelete}
                              >
                                {t('DELETE_TITLE')}
                              </StyledButton>
                            </>
                          )}
                          {(status === 'published' ||
                            status === 'upcoming') && (
                              <>
                                <StyledButton
                                  icon={<EyeInvisibleIcon />}
                                  size="large"
                                  type="primary"
                                  onClick={onUnpublish}
                                  disabled={!can_be_unpublished}
                                >
                                  {t('UNPUBLISH_TITLE')}
                                </StyledButton>
                                <StyledButton
                                  danger
                                  icon={<DeleteIcon />}
                                  size="large"
                                  type="primary"
                                  onClick={onDelete}
                                >
                                  {t('DELETE_TITLE')}
                                </StyledButton>
                              </>
                            )}
                        </>
                      )}
                      {user?.role === RECRUITER_ROLE && (
                        <>
                          <StyledButton
                            icon={<ChatIcon />}
                            size="large"
                            type="ghost"
                            onClick={openIntercom}
                          >
                            {t('ASK_QUESTION')}
                          </StyledButton>

                          <StyledButton
                            icon={<PdfIcon />}
                            size="large"
                            type="ghost"
                            onClick={getPdf}
                          >
                            {t('DOWNLOAD_PDF')}
                          </StyledButton>

                          <StyledButton
                            icon={<AddUserIcon />}
                            size="large"
                            type="primary"
                            onClick={() => handleIntroduction(true)}
                            disabled={status === 'unpublished' || status === 'on_hold' || job.recruiter_points === 0 || (job.is_active_recruiter_points !== null && !job.is_active_recruiter_points)}
                          >
                            {t('APPOINT_TITLE_2')}
                          </StyledButton>
                          <PointsLeft points={job.recruiter_points} disabled={status === 'unpublished' || status === 'on_hold' || job.recruiter_points === 0 || (job.is_active_recruiter_points !== null && !job.is_active_recruiter_points)} />
                        </>
                      )}
                      {!user && (
                        <StyledButton
                          icon={<CheckIcon />}
                          size="large"
                          type="primary"
                          onClick={() => {
                            storeJobId(id);
                            return history.push(
                              REGISTER
                            )
                          }
                          }
                        >
                          {t('RECEIVING_CANDIDATES')}
                        </StyledButton>
                      )}
                    </ActionButtons>
                  </Col>
                </Row>
              </BannerInfoText>
            </BannerInfo>
          )}
        </BannerContent>
      </BannerOverlay>
      <UnpublishJobModal onAction={(res) => {
        onAction((prev: any) => ({ ...prev, status: res.data.status }));
      }} job={job} show={isUnpublishModalOpen} />
    </BannerWrapper>
  );
};

export default Banner;
