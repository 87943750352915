import { useCallback, useEffect, useState } from 'react';
import { Form, message, Col, Row, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import FormTitle from '../../auth/FormTitle';
import Button from '../../../UI/buttons/Button';
import { updateJobOffer, getJobOffer } from '../../../api/jobs';
import FormItem from '../FormItem';
import { requiredRule } from '../../../utils/validationRules';

import { Job } from '../../../types';
import SelectInput from '../../../UI/inputs/Select';
import Input from '../../../UI/inputs/Input';
import Textarea from '../../../UI/inputs/Textarea';
import RouterBlocker from '../../jobs/RouterBlocker';
import {
  ColAction,
  DeleteIcon,
  FormActions,
  FormSubtitle,
  CareerPathBranchLabel,
  FormSubtitleHint,
} from './styles';
import EmploymentTypeSelectFormSection from '../shared/employment-types/EmploymentTypeSelectFormSection';
import {
  mapEmploymentTypeApiToForm,
  mapEmploymentTypeFormToApi,
} from '../shared/employment-types/helpers';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import { JOBS } from '../../../constants/routes';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';

const { Option } = Select;

type JobOfferFormProps = {
  job: Job;
  onFinish: (id: string) => void;
}

const JobOfferForm = ({ job, onFinish }: JobOfferFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const [saveLoading, setSaveLoading] = useState(false);
  const [careerPath, setCareerPath] = useState<string[][]>([[], []]);

  const getInitialState = useCallback(
    async (jobId: string) => {
      const res = await getJobOffer(jobId);

      const formValues = {
        ...res.data,
        laptop_phone_enabled: [
          res.data?.laptop_enabled && 'laptop',
          res.data?.phone_enabled && 'phone',
        ]
          .filter((_) => !!_)
          .join('_'),
        bonus_scheme: res.data?.bonus_scheme?.toString(),
        budget_education: res.data?.budget_education?.toString(),
        budget_lease_car: res.data?.budget_lease_car?.toString(),
        budget_mobile: res.data?.budget_mobile?.toString(),
        pension_scheme: res.data?.pension_scheme?.toString(),
        employment_type: res.data.employment_type
          ? mapEmploymentTypeApiToForm(res.data?.employment_type)
          : {},
      };

      if (res.data?.career_path && res.data?.career_path !== '') {
        const jsonField = JSON.parse(res.data?.career_path);
        setCareerPath(jsonField);
        const formValuesWithCareerPath = {
          ...formValues,
          branch_1: jsonField[0].map((el: string) => ({ career: el })),
          branch_2: jsonField[1].map((el: string) => ({ career: el })),
        };
        form.setFieldsValue(formValuesWithCareerPath);
      } else {
        form.setFieldsValue(formValues);
      }
    },
    [form],
  );

  useEffect(() => {
    if (job && job.id) {
      getInitialState(job.id);
    }
  }, [job, getInitialState]);

  const collectFormValues = () => {
    const formValues = form.getFieldsValue();
    const draft = {
      ...formValues,
      career_path: JSON.stringify([
        formValues.branch_1
          ? formValues.branch_1.map((el: any) => el.career)
          : [],
        formValues.branch_2
          ? formValues.branch_2.map((el: any) => el.career)
          : [],
      ]),
      employment_type: mapEmploymentTypeFormToApi(formValues.employment_type),
    };
    let equipmentFields = draft.laptop_phone_enabled?.split('_') ?? [];
    draft.laptop_enabled = false;
    draft.phone_enabled = false;
    equipmentFields?.forEach((f: string) => {
      draft[f + '_enabled'] = true;
    });
    return draft;
  };

  const saveJobInfo = async (isDraft: boolean) => {
    let jobId = job?.id;
    if (jobId) {
      const draft = collectFormValues();
      await updateJobOffer(jobId, draft);
      message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
      return jobId;
    }
    return jobId;
  };

  const handleNext = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (job?.id) {
      handleSave(e)
        .then(() => {
          onFinish(job.id);
        })
        .catch((error) => {
          if (error.response.data?.error_code === 'access_forbidden_error') {
            return;
          }
        });
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    await form.validateFields();
    try {
      setSaveLoading(true);
      await saveJobInfo(true);
      await getInitialState(job?.id);
      setBlockTransition(false);
      setSaveLoading(false);
    } catch (error) {
      if (
        (error as any).response.data.error_code === 'access_forbidden_error'
      ) {
        message.error(t('JOB_UNDER_REVIEW'), 5);
        setBlockTransition(false);
        setSaveLoading(false);
        throw error;
      }
      message.error((error as any).response.data.message, 5);
      setBlockTransition(false);
      setSaveLoading(false);
    }
  };

  const [blockTransition, setBlockTransition] = useState(false);
  const employmentTypes = useEmploymentTypes();

  const handleFieldsChange = (changed: any, all: any) => {
    setBlockTransition(true);
  };

  useEffect(() => {
    if (employmentTypes.length <= 0) return;
    const newValue = {
      employment_type: {
        type: employmentTypes[0],
      },
      ...form.getFieldsValue(),
    };
    form.setFieldsValue(newValue);
  }, [form, employmentTypes]);

  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
      >
        <FormTitle text={t('WHAT_IS_OFFERED')} />

        <EmploymentTypeSelectFormSection
          name={['employment_type']}
          getSelectedEmploymentType={() =>
            form.getFieldValue(['employment_type', 'type', 'id'])
          }
          displayContext="job"
        />

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('LAPTOP_PHONE')}
                name="laptop_phone_enabled"
                tooltip={
                  t('JOB_OFFER_FORM_LAPTOP_PHONE_ENABLED_TOOLTIP')
                    ? {
                      title: t('JOB_OFFER_FORM_LAPTOP_PHONE_ENABLED_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <SelectInput
                  size="large"
                  filterOption={true}
                  allowClear
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  defaultActiveFirstOption
                >
                  <Option key="laptop_phone" value="laptop_phone">
                    {t('LAPTOP_PHONE')}
                  </Option>
                  <Option key="laptop" value="laptop">
                    {t('LAPTOP')}
                  </Option>
                  <Option key="phone" value="phone">
                    {t('PHONE_TEXT')}
                  </Option>
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('EXTRAS')}
          name="extras"
          tooltip={
            t('JOB_OFFER_FORM_EXTRAS_TOOLTIP')
              ? {
                title: t('JOB_OFFER_FORM_EXTRAS_TOOLTIP'),
                icon: <InfoIcon />,
              }
              : false
          }
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>

        <FormSubtitle>{t('CAREER_PATH')}</FormSubtitle>
        <FormSubtitleHint>{t('CAREER_PATH_HINT')}</FormSubtitleHint>

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            {careerPath.map((branch, branchIndex) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <CareerPathBranchLabel>
                  {t('BRANCH') + ' ' + (branchIndex + 1)}
                </CareerPathBranchLabel>
                <Form.List name={`branch_${branchIndex + 1}`}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <FormItem className={'no-margin'}>
                            <Row gutter={16}>
                              <Col
                                xs={{ span: 16 }}
                                sm={{ span: 16 }}
                                md={{ span: 16 }}
                                lg={{ span: 16 }}
                              >
                                <FormItem
                                  {...restField}
                                  name={[name, 'career']}
                                  fieldKey={[fieldKey, 'career']}
                                  label={t('POSITION') + ' ' + (index + 1)}
                                  rules={requiredRule(t)}
                                >
                                  <Input size="large" />
                                </FormItem>
                              </Col>
                              <Col
                                xs={{ span: 1 }}
                                sm={{ span: 1 }}
                                md={{ span: 1 }}
                                lg={{ span: 1 }}
                              >
                                <ColAction>
                                  <DeleteIcon
                                    onClick={() => {
                                      remove(name);
                                    }}
                                  />
                                </ColAction>
                              </Col>
                            </Row>
                          </FormItem>
                        ),
                      )}
                      <FormItem>
                        <Button
                          type="ghost"
                          size="small"
                          icon={<AddIcon />}
                          onClick={() => add()}
                        >
                          {t('ADD_POSITION')}
                        </Button>
                      </FormItem>
                    </>
                  )}
                </Form.List>
              </Col>
            ))}
          </Row>
        </FormItem>

        <FormItem
          label={t('DESCRIBE_CAREER_PATH')}
          name="growth_path"
          tooltip={
            t('JOB_OFFER_FORM_GROWTH_PATH_TOOLTIP')
              ? {
                title: t('JOB_OFFER_FORM_GROWTH_PATH_TOOLTIP'),
                icon: <InfoIcon />,
              }
              : false
          }
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>

        <FormActions>
          <Button
            type="ghost"
            loading={saveLoading}
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleSave(e).then(() => history.push(`${JOBS}/${job?.id}`));
              });
            }}
          >
            {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              form.validateFields().then((res) => {
                handleNext(e);
              });
            }}
          >
            {t('NEXT_TITLE')}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default JobOfferForm;
