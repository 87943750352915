import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Recruiter, Specialization } from '../types';
import { useAppSelector } from '../store/hooks';
import {
  SortOrder,
  SortingType,
} from '../components/recruiter/RecruiterManagementBoard';
import { getSpecializationList } from '../api/specialization';
import { structuredDomains } from '../utils/general';

import {
  deleteRecruiterCompanyAgreement,
  getRecrutersForRecruiterManagement,
} from '../api/recruiter';
import RecruiterManagementTable from '../components/recruitment_management/RecruiterManagementTable';
import RecruiterManagementFilters from '../components/recruitment_management/RecruiterManagementFilters';
import Loader from '../UI/Loader';
import { DASHBOARD } from '../constants/routes';
import RecruiterManagementTagColors from '../components/recruitment_management/RecruiterManagementTagColors';
import PageWrapper from '../components/layout/PageWrapper';
import Header from '../components/layout/Header';

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;

const RecruiterManagement = () => {
  const [t] = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const history = useHistory();
  const talentSupplierModuleEnabled =
    user?.company?.talent_supplier_module_enabled;

  const loader = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState<any[]>([]);
  const [allDomains, setAllDomains] = useState<Specialization[]>([]);
  const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
  const [showMarketplaceRecruiters, setShowMarketplaceRecruiters] =
    useState<boolean>(true);
  const [next, setNext] = useState<string | null>(null);
  const companyName = user?.company?.name;
  const companyId = user?.company?.id;
  const [currentSortingType, setCurrentSortingType] =
    useState<SortingType>('TYPE');
  const [currentSortingOrder, setCurrentSortingOrder] =
    useState<SortOrder>('DESC');
  const [sortQueryString, setSortQueryString] = useState('-own_recruiter');

  const handleSortingByHeader = (sortType: SortingType) => {
    if (sortType !== currentSortingType) {
      setCurrentSortingType(sortType);
      setCurrentSortingOrder('ASC');
    } else {
      if (currentSortingOrder === 'DESC') setCurrentSortingOrder('ASC');
      if (currentSortingOrder === 'ASC') setCurrentSortingOrder('DESC');
    }
  };

  let onFilter = (_: any, allValues: any) => {
    let querySearch: any[] = [];

    allValues.forEach((elem: any) => {
      if (elem['name'][0] === 'rating' || elem['name'][0] === 'category')
        return;
      querySearch.push({ param: elem['name'][0], value: elem.value });
    });

    let queryString = querySearch
      .filter((elem) => elem.value !== undefined)
      .map((elem) => {
        if (Array.isArray(elem.value)) {
          elem.value = elem.value.join(',');
          return elem;
        }
        return elem;
      })
      .map((elem) => `${elem.param}=${elem.value}`)
      .join('&');

    queryString = queryString + `&sort=${sortQueryString}`;
    setLoading(true);
    getRecrutersForRecruiterManagement(
      showMarketplaceRecruiters,
      `${companyName}&${queryString}`,
    ).then((res) => {
      setRecruiters(res.data.results);
      setNext(res.data.pagination.next);
    });
    setLoading(false);
  };

  onFilter = debounce(onFilter, 300, { trailing: true });

  const handleObserver = useCallback(
    (entities: any) => {
      const target = entities[0];
      if (target.isIntersecting && next) {
        getRecrutersForRecruiterManagement(
          showMarketplaceRecruiters,
          `${companyName}&cursor=${next}&sort=${sortQueryString}`,
        )
          .then((res) => {
            let newRecs = res.data.results;
            setRecruiters((prevState: Recruiter[]) => [
              ...prevState,
              ...newRecs,
            ]);
            setNext(res.data.pagination.next);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyName, next, sortQueryString],
  );

  const handleDeleteRecruiterCompanyAgreement = async (
    recruiter: Recruiter,
  ) => {
    try {
      if (!user?.company?.id) return;

      const response = await deleteRecruiterCompanyAgreement({
        recruiter_id: recruiter.id,
        client_company_id: user?.company.id,
      });

      if (response?.status === 200) {
        message.success("Recruiter's agreement has been deleted!");
        setRecruiters((prevState: Recruiter[]) =>
          prevState.map((rec) => ({
            ...rec,
            own_recruiter: rec.id === recruiter.id ? false : rec.own_recruiter,
          })),
        );
      } else if (response?.status === 204) {
        message.error(
          'Unable to remove recruiter. Candidates are already process.',
        );
      }
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      getSpecializationList().then((res) => {
        setAllDomains(
          res.data.map((x: any) => ({
            ...x,
            color: RecruiterManagementTagColors[Math.floor(Math.random() * RecruiterManagementTagColors.length)],
          })),
        );
        setDomains(structuredDomains(res.data));
      });
      getRecrutersForRecruiterManagement(
        showMarketplaceRecruiters,
        `${companyName}&sort=${sortQueryString}`,
      ).then((res) => {
        setRecruiters(res.data.results);
        setNext(res.data.pagination.next);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [companyName, sortQueryString, showMarketplaceRecruiters]);

  useEffect(() => {
    switch (currentSortingType) {
      case 'NAME':
        if (currentSortingOrder === 'ASC')
          setSortQueryString('user__first_name');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('-user__first_name');
        break;
      case 'TYPE':
        if (currentSortingOrder === 'ASC') setSortQueryString('own_recruiter');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('-own_recruiter');
        break;
      case 'RATING':
        if (currentSortingOrder === 'ASC')
          setSortQueryString('-overall_rating');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('overall_rating');
        break;
    }
  }, [currentSortingType, currentSortingOrder]);

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!next) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [next, handleObserver]);

  useEffect(() => {
    if (!talentSupplierModuleEnabled) {
      history.push(DASHBOARD);
    }
  }, [talentSupplierModuleEnabled, history]);

  return (
    <PageWrapper>
      <Header title={t('RECRUITER_MANAGEMENT')}>
        <span></span>
      </Header>
      <RecruiterManagementFilters
        domains={domains}
        subUser={user?.sub_user}
        onFilter={onFilter}
        showMarketplaceRecruiters={showMarketplaceRecruiters}
        setShowMarketplaceRecruiters={setShowMarketplaceRecruiters}
      />
      {companyId && (
        <Loader spinning={loading}>
          {!loading && recruiters.length === 0 ? (
            <NoResultText>{t('NO_RESULT')}</NoResultText>
          ) : (
            <RecruiterManagementTable
              recruiters={recruiters}
              domains={allDomains}
              handleDeleteRecruiterCompanyAgreement={
                handleDeleteRecruiterCompanyAgreement
              }
              handleSortingByHeader={handleSortingByHeader}
              currentSortingOrder={currentSortingOrder}
              currentSortingType={currentSortingType}
            />
          )}
          <div className="loader" ref={loader} />
        </Loader>
      )}
    </PageWrapper>
  );
};

export default RecruiterManagement;
