import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/hireport-en.json';
import nl from './locales/hireport-nl.json';
import fr from './locales/hireport-fr.json';

const lng = localStorage.getItem('app_locale') || 'en';
export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    nl: { translation: nl },
    fr: { translation: fr },
  },
  lng: lng,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
});
