import { Select, Col, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import FormItem from '../../FormItem';
import SelectInput from '../../../../UI/inputs/Select';
import { useTranslation } from 'react-i18next';
import { InternalNamePath } from 'antd/lib/form/interface';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import styled from 'styled-components';
import EmploymentTypeFieldsFormSection, { EmploymentTypeFieldsFormSectionProps } from './EmploymentTypeFieldsFormSection';
import useEmploymentTypes from '../../../../hooks/useEmploymentTypes';

const ActionItem = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  margin: auto;
  cursor: pointer;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.danger {
    color: #eb5757;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const AutoMarginCol = styled(Col)`
  margin: auto;
  text-align: right;
`;

type EmploymentTypeSelectFormSectionProps = {
  name: InternalNamePath;
  getSelectedEmploymentType: () => string;
  onRemove?: (() => void) | null;
  displayContext: EmploymentTypeFieldsFormSectionProps['displayContext'];
  onEmploymentTypeChange?: (newType: string) => void;
};

const EmploymentTypeSelectFormSection = ({ name = [], getSelectedEmploymentType, onRemove, displayContext, onEmploymentTypeChange }: EmploymentTypeSelectFormSectionProps) => {
  const { t } = useTranslation();
  const employmentTypes = useEmploymentTypes();
  const colSpan = onRemove ? 23 : 24;
  return (
    <>
      <Row gutter={16} justify="space-between">
        <Col
          xs={{ span: colSpan }}
          sm={{ span: colSpan }}
          md={{ span: colSpan }}
          lg={{ span: colSpan }}
        >
          <FormItem
            label={t('Type of employment')}
            name={[...name, 'type', 'id']}>
            <SelectInput
              size="large"
              filterOption={true}
              defaultActiveFirstOption={true}
              suffixIcon={
                <CaretDownOutlined style={{ color: '#000' }} />
              }
              onChange={onEmploymentTypeChange}
            >
              {employmentTypes.map((employmentType) => (
                <Select.Option value={employmentType.id}>{t(employmentType.name)}</Select.Option>
              ))}
            </SelectInput>
          </FormItem>
        </Col>
        {onRemove &&
          <AutoMarginCol
            xs={{ span: 1 }}
            sm={{ span: 1 }}
            md={{ span: 1 }}
            lg={{ span: 1 }}
          >
            {onRemove &&
              <ActionItem
                className="danger"
                onClick={onRemove}
              >
                <DeleteIcon />
              </ActionItem>
            }
          </AutoMarginCol>
        }
      </Row>
      <FormItem shouldUpdate className="no-margin">
        {() => {
          const selectedEmploymentType = getSelectedEmploymentType();
          return (
            <>
              {employmentTypes.map((employmentType) => (
                <EmploymentTypeFieldsFormSection
                  key={employmentType.id}
                  name={name}
                  employmentType={employmentType}
                  displayContext={displayContext}
                  hidden={employmentType.id !== selectedEmploymentType}
                />
              ))}
            </>
          )
        }}
      </FormItem>
    </>
  )
}

export default EmploymentTypeSelectFormSection