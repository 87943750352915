import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageWrapper from '../components/layout/PageWrapper';
import Header from '../components/layout/Header';
import { useTranslation } from 'react-i18next';
import PaymentHeaderFilter from '../components/payments/PaymentHeaderFilter';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../UI/Loader';
import PaymentsTable from '../components/payments/PaymentsTable';
import { Payment } from '../types';
import { getPayments } from '../api/payments';
import { getRandomColor } from '../constants/palette';
import ReactGa from 'react-ga4';
const PaymentList = styled.div`
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  padding: 0 2rem 0.5rem;
  width: 100%;
`;
const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;
const Payments = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [next, setNext] = useState('');
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<Payment[]>([]);
  const loader = useRef<HTMLDivElement>(null);
  const params = new URLSearchParams(history.location.search);

  const filter = params.get('filter');
  useEffect(() => {
    setLoading(true);
    getPayments(filter ? '?paid=false' : '').then((res) => {
      setPayments(
        res.data.results.map((res: Payment) => ({
          ...res,
          company: { ...res.company, color: getRandomColor() },
          recruiter: { ...res.recruiter, color: getRandomColor() },
        })),
      );
      setNext(res.data.pagination.next);
      setLoading(false);
    });
  }, [filter]);
  const handleObserver = useCallback(
    (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + next
        : '?cursor=' + next
        }`;
      const target = entities[0];
      if (target.isIntersecting && next) {
        getPayments(cursor).then((res) => {
          setPayments((prevState) => [
            ...prevState,
            ...res.data.results.map((res: Payment) => ({
              ...res,
              company: { ...res.company, color: getRandomColor() },
              recruiter: { ...res.recruiter, color: getRandomColor() },
            })),
          ]);
          setNext(res.data.pagination.next);
          setLoading(false);
        });
      }
    },
    [next, history.location.search],
  );
  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 200px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!next) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [next, handleObserver]);
  useEffect(() => {
    ReactGa.pageview('/payments');
  }, []);
  return (
    <PageWrapper>
      <Header title={t('PAYMENTS')}>
        <PaymentHeaderFilter />
        <span></span>
      </Header>
      <Loader spinning={loading}>
        <PaymentList>
          {!loading && !payments.length ? (
            <NoResultText>{t('NO_RESULT')}</NoResultText>
          ) : (
            <PaymentsTable payments={payments} />
          )}
        </PaymentList>
        <div className="loader" ref={loader} />
      </Loader>
    </PageWrapper>
  );
};

export default Payments;
