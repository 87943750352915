import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import { ReactComponent as CaseIcon } from '../../icons/case.svg';
import { ReactComponent as QuoteIcon } from '../../icons/quote.svg';
import SectionItem from '../../components/jobs/singleJob/SectionItem';
import SkillSection from '../../components/jobs/singleJob/SkillSection';
import ActivitySection from '../../components/jobs/singleJob/ActivitySection';
import BenefitSection from '../../components/jobs/singleJob/BenefitSection';
import TeamSection from '../../components/jobs/singleJob/TeamSection';
import CompanyFactSection from '../../components/jobs/singleJob/CompanyFactSection';
import CareerSection from '../../components/jobs/singleJob/CareerSection';
import ColleagueSection from '../../components/jobs/singleJob/ColleagueSection';
import DescriptionSection from '../../components/jobs/singleJob/DescriptionSection';
import ApplicationProcess from '../../components/jobs/singleJob/ApplicationProcess';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import SimilarJobSection from '../../components/jobs/singleJob/SimilarJobSection';
import CandidateIntroduceModal from '../../components/candidates/CandidateIntroduceModal';
import CandidateChooseModal from '../../components/candidates/CandidateChooseModal';
import Loader from '../../UI/Loader';
import CandidateAddForm from '../../components/form/CandidateAddForm';
import RoundAddButton from '../../UI/buttons/RoundAddButton';
import Banner from '../../components/jobs/singleJob/Banner';
import ToolSection from '../../components/jobs/singleJob/ToolSection';
import {
  SingleJobBody,
  InterviewDescription,
  ApplicationProcessWrap,
} from './styles';
import useSingleJob from './hooks';
import ComingSoon from '../../components/helper/ComingSoon';
import { getJob } from '../../api/jobs';
import useEmploymentTypes from '../../hooks/useEmploymentTypes';
import getEmploymentTypeSummary from '../../utils/getEmploymentTypeSummary';
import RecruiterFeeSection from '../../components/jobs/singleJob/RecruiterFeeSection';
import useHirePortTheme from '../../hooks/useHirePortTheme';

type SingleJobProps = {
  isPublic: boolean;
  showBackButton?: boolean;
}

const SingleJob = ({ isPublic, showBackButton = true }: SingleJobProps) => {
  const [t] = useTranslation();
  const theme = useHirePortTheme();

  const {
    actions: {
      trackSection,
      addCandidateItem,
      editCandidate,
      setIntroducedList,
      setAddCandidate,
      setStartIntroduction,
      setJob,
      setSelectedCandidate,
    },
    data: {
      job,
      data: supplementalJobData,
      introducedList,
      addCandidate,
      addedCandidate,
      startIntroduction,
      languages,
      activityFeed,
      loading,
      roles,
      user,
      selectedCandidate,
      tree,
      id,
      editModal,
      isRecruiter,
    },
  } = useSingleJob(isPublic);
  const isClient = user?.role === CLIENT_ROLE;

  const [favorite, setFavorite] = useState<boolean>(false);
  const employmentTypes = useEmploymentTypes();

  useEffect(() => {
    setFavorite(!!job?.is_favorite);
  }, [job?.is_favorite]);

  const tollsGraph = useMemo(() => {
    return <ToolSection data={job?.tools_used} />;
  }, [job?.tools_used]);

  const teamGraph = useMemo(() => {
    if (!job?.team_roles.length) {
      return;
    }
    return (
      <TeamSection
        roles={roles}
        team={job?.team_roles || []}
        languages={job?.spoken_languages ? job?.spoken_languages : []}
        languageList={languages}
      />
    );
  }, [roles, job, languages]);

  const { softSkills, industrySkills, minEducation, preferableEducation } =
    useMemo(() => {
      let softSkills: string[] = [];
      let industrySkills: string[] = [];
      let minEducation: { id: string; name: string }[] =
        job?.min_education || [];
      let preferableEducation: { id: string; name: string }[] =
        job?.preferable_education || [];

      if (job?.soft_skills?.length) {
        softSkills = job?.soft_skills.split(',');
      }

      if (job?.industry_experiences?.length) {
        industrySkills = job?.industry_experiences.split(',');
      }

      if (job?.min_education_custom?.length) {
        minEducation = [
          ...minEducation,
          { id: job.min_education_custom, name: job.min_education_custom },
        ];
      }

      if (job?.preferable_education_custom?.length) {
        preferableEducation = [
          ...preferableEducation,
          {
            id: job?.preferable_education_custom,
            name: job?.preferable_education_custom,
          },
        ];
      }

      return {
        softSkills,
        industrySkills,
        minEducation,
        preferableEducation,
      };
    }, [job]);

  const employmentTypeSummary =
    job && getEmploymentTypeSummary(t, job?.employment_type, employmentTypes);
  const additionalWorkingConditions = (
    employmentTypeSummary?.fields['working_conditions'] || []
  ).map(
    (workingCondition) =>
      `${workingCondition.label}: ${workingCondition.value || ''}`,
  );

  return (
    <div style={{ backgroundColor: theme.lozengeColor }}>
      <Loader spinning={loading}>
        <Banner
          role={user?.role}
          languageList={languages}
          onAction={setJob}
          handleIntroduction={setStartIntroduction}
          loading={loading}
          languages={job?.languages ? job?.languages : []}
          activityFeed={activityFeed}
          job={job}
          onSetFavorite={setFavorite}
          favorite={favorite}
          showBackButton={showBackButton}
        />
        <SingleJobBody>
          <Row gutter={[16, 16]}>
            {(isRecruiter || isClient) && job && (
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }}>
                <SectionItem
                  title={t('RECRUITER_JOB_DETAILS')}
                  name="fee_details"
                  jobFeedbacks={job?.job_feedbacks}
                  trackSection={trackSection}
                  routeToEdit={isClient && `/edit/${job.id}/fees`}
                >
                  <RecruiterFeeSection job={job} isClient={isClient} />
                </SectionItem>
              </Col>
            )}
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('ABOUT_COMPANY')}
                name="about_company"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/company_info`}
              >
                <DescriptionSection text={job?.company?.description || ''}>
                  {!job?.company?.description && (
                    <ComingSoon
                      onSetFavorite={setFavorite}
                      favorite={favorite}
                      job={job}
                    />
                  )}
                  {job?.company?.difference_description && (
                    <SectionItem title={t('COMPANY_USPS')}>
                      <p>{job?.company?.difference_description}</p>
                    </SectionItem>
                  )}
                </DescriptionSection>
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('JOB_DESCRIPTION')}
                name="job_description"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/info`}
              >
                <DescriptionSection text={job?.description || ''}>
                  {!job?.description && (
                    <ComingSoon
                      onSetFavorite={setFavorite}
                      favorite={favorite}
                      job={job}
                    />
                  )}
                  {job?.current_projects && (
                    <SectionItem title={t('CURRENT_PROJECTS')}>
                      <p>{job?.current_projects}</p>
                    </SectionItem>
                  )}
                  {job?.ideal_candidate && (
                    <SectionItem title={t('IDEAL_CANDIDATE')}>
                      <p>{job?.ideal_candidate}</p>
                    </SectionItem>
                  )}
                  {job?.problems_description && (
                    <SectionItem title={t('CANDIDATE_SOLVES_PROBLEM')}>
                      <p>{job?.problems_description}</p>
                    </SectionItem>
                  )}
                </DescriptionSection>
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('MATCHING')}
                name="skills"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/profile`}
              >
                <SkillSection
                  title="Must"
                  skills={job?.key_skills || []}
                  education={minEducation}
                  certificates={job?.min_certificates}
                  languages={job?.languages}
                  languageList={languages}
                />

                <SkillSection
                  title={t('PREFERABLE')}
                  skills={job?.preferable_skills || []}
                  education={preferableEducation}
                  certificates={job?.preferable_certificates}
                  languages={
                    job?.preferable_language
                      ? [...job?.preferable_language]
                      : []
                  }
                  light={true}
                  languageList={languages}
                />

                {!!softSkills.length && (
                  <SkillSection
                    title={t('SOFT_SKILLS')}
                    skills={[]}
                    education={[]}
                    certificates={[]}
                    languages={[]}
                    languageList={{}}
                    softSkills={softSkills}
                  />
                )}

                {!!industrySkills.length && (
                  <SkillSection
                    title={t('INDUSTRY_EXP')}
                    skills={[]}
                    education={[]}
                    certificates={[]}
                    languages={[]}
                    languageList={{}}
                    industrySkills={industrySkills}
                  />
                )}
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('Compensation & benefits')}
                name="what_you_get"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                bg_image={<CaseIcon className="section-bg" />}
                routeToEdit={isClient && `/edit/${job?.id}/offer`}
              >
                <BenefitSection job={job || undefined} />
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('WORKING_CONDITIONS')}
                name="company_facts"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                bg_image={<HomeIcon className="section-bg" />}
                routeToEdit={isClient && `/edit/${job?.id}/profile`}
              >
                <CompanyFactSection
                  visa={job?.is_visa_available}
                  daysAtTheOffice={job?.days_at_the_office}
                  daysWorkingFromHome={job?.days_working_from_home}
                  holidays={job?.holidays_number}
                  relocationPackage={job?.relocation_package}
                  relocation={job?.relocation}
                  workingType={job?.working_type}
                  additional={additionalWorkingConditions}
                />
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
              <SectionItem
                title={t('COMPANY_CULTURE_LABEL')}
                name="company_culture"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/company_info`}
              >
                {job?.company.company_culture ? (
                  <DescriptionSection text={job?.company.company_culture} />
                ) : (
                  <ComingSoon
                    onSetFavorite={setFavorite}
                    favorite={favorite}
                    job={job}
                  />
                )}
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }}>
              <SectionItem
                title={
                  <>
                    {t('TEAM')}{' '}
                    <span>
                      {job?.team_members_num
                        ? job?.team_members_num + ' ' + t('PEOPLE')
                        : ''}
                    </span>
                  </>
                }
                name="team"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/team`}
              >
                {teamGraph}
                {!job?.team_members_num && (
                  <ComingSoon
                    onSetFavorite={setFavorite}
                    favorite={favorite}
                    job={job}
                  />
                )}
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }}>
              <SectionItem
                title={t('WHAT_WILL_BE_DOING')}
                name="will_be_doing"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/info`}
              >
                {job?.activities.length ? (
                  <ActivitySection activities={job?.activities || []} />
                ) : (
                  <ComingSoon
                    onSetFavorite={setFavorite}
                    favorite={favorite}
                    job={job}
                  />
                )}
              </SectionItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }}>
              <SectionItem
                title={t('TOOLS_USED')}
                name="tools_used"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/info`}
              >
                {job?.tools_used.length ? (
                  tollsGraph
                ) : (
                  <ComingSoon
                    onSetFavorite={setFavorite}
                    favorite={favorite}
                    job={job}
                  />
                )}
              </SectionItem>
            </Col>
            {!!tree.length && (
              <Col
                xs={{ span: 24 }}
                md={{ span: 24 }}
                xl={{ span: job?.colleagues?.length ? 12 : 24 }}
              >
                <SectionItem
                  title={t('CAREER_PATH')}
                  dark={true}
                  name="career_path"
                  jobFeedbacks={job?.job_feedbacks}
                  trackSection={trackSection}
                  routeToEdit={isClient && `/edit/${job?.id}/offer`}
                >
                  <CareerSection career={tree} description={job?.growth_path} />
                </SectionItem>
              </Col>
            )}
            {!!job?.colleagues?.length && (
              <Col
                xs={{ span: 24 }}
                md={{ span: 24 }}
                xl={{ span: tree.length ? 12 : 24 }}
              >
                <SectionItem
                  title={t('COLLEAGUES')}
                  dark={true}
                  bg_image={<QuoteIcon className="section-bg" />}
                  name="colleagues"
                  jobFeedbacks={job?.job_feedbacks}
                  trackSection={trackSection}
                  routeToEdit={isClient && `/edit/${job?.id}/team`}
                >
                  <ColleagueSection
                    colleagues={job?.colleagues}
                    className={
                      !job?.job_feedbacks?.find(
                        (el) => el.tag_name === 'colleagues' && isRecruiter,
                      )
                        ? 'left'
                        : ''
                    }
                  />
                </SectionItem>
              </Col>
            )}
            <Col
              className={'application-process'}
              xs={{ span: 24 }}
              md={{ span: 24 }}
              xl={{ span: 24 }}
            >
              <SectionItem
                title={t('APPLICATION_PROCESS')}
                name="application_process"
                jobFeedbacks={job?.job_feedbacks}
                trackSection={trackSection}
                routeToEdit={isClient && `/edit/${job?.id}/process`}
              >
                <ApplicationProcessWrap>
                  {job?.interview_description ? (
                    <InterviewDescription>
                      {job?.interview_description}
                    </InterviewDescription>
                  ) : (
                    <ComingSoon
                      recruiterShortText={true}
                      onSetFavorite={setFavorite}
                      favorite={favorite}
                      job={job}
                    />
                  )}
                  {job?.stages && (
                    <ApplicationProcess
                      stages={job.stages}
                      duration={job.total_duration || 0}
                    />
                  )}
                </ApplicationProcessWrap>
              </SectionItem>
            </Col>
          </Row>
          {user?.role === RECRUITER_ROLE && <SimilarJobSection jobId={id} />}
          {startIntroduction && (
            <>
              <CandidateChooseModal
                candidate={selectedCandidate}
                {...supplementalJobData}
                jobId={id}
                recruiterPoints={job?.recruiter_points}
                introducedList={introducedList}
                roles={roles}
                show={startIntroduction}
                setShow={setStartIntroduction}
                setCandidate={setSelectedCandidate}
                addedCandidate={addedCandidate}
              />
              {!addCandidate && (
                <RoundAddButton
                  onClick={() => setAddCandidate(true)}
                  style={{ zIndex: 99999 }}
                />
              )}
            </>
          )}
          {selectedCandidate &&
            job?.recruiter_points &&
            job?.recruiter_points > 0 &&
            job.is_active_recruiter_points !== false && (
              <CandidateIntroduceModal
                backButton={startIntroduction}
                jobId={id}
                job={job}
                addToList={setIntroducedList}
                candidate={selectedCandidate}
                setCandidate={setSelectedCandidate}
                skills={supplementalJobData.skills}
                certificates={supplementalJobData.certificates}
                education={supplementalJobData.education}
                languages={job?.languages ? job?.languages : []}
                languageList={languages}
                employmentType={employmentTypes.find(
                  (employmentType) =>
                    job.employment_type?.type.id === employmentType.id,
                )}
                onConfirmFinished={() => {
                  getJob(id).then((res) => {
                    setJob(res.data);
                  });
                }}
              />
            )}
          <CandidateAddForm
            cities={supplementalJobData.cities}
            certificates={supplementalJobData.certificates}
            education={supplementalJobData.education}
            onAction={editModal ? editCandidate : addCandidateItem}
            roles={roles}
            skills={supplementalJobData.skills}
            domains={supplementalJobData.domains}
            seniority={supplementalJobData.seniority}
            visible={addCandidate}
            changeVisibility={setAddCandidate}
            role={user?.role}
          />
        </SingleJobBody>
      </Loader>
    </div>
  );
};

export default SingleJob;
