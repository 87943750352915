import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import FormTitle from '../auth/FormTitle';
import { StyledModal } from '../jobs/UnpublishJobModal';
import FormItem from '../form/FormItem';
import Button from '../../UI/buttons/Button';
import SelectInput from '../../UI/inputs/Select';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { Recruiter, Specialization } from '../../types';
import { getSpecializationList } from '../../api/specialization';
import AddCompanyRecruiterForm from './AddCompanyRecruiterForm';
import {
  getRecrutersSelectedForJob,
  getPreviouslySelectedRecruiters,
} from '../../api/recruiter';
import RecruiterManagementBoardFooter from './RecruiterManagementBoardFooter';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as EmailIcon } from '../../icons/info-email.svg';
import styled from 'styled-components';
import NewSearchInput from '../../UI/inputs/NewSearchInput';
import { RATING_FILTERS } from '../../constants/recruiters';
import { structuredDomains } from '../../utils/general';
import RecruiterSelectTable from './RecruiterSelectTable';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import Loader from '../../UI/Loader';
import RecruiterManagementTagColors from '../recruitment_management/RecruiterManagementTagColors';
import { TEST_UI_KEYS, withTestUIWrapper } from '../helper/TestUIWrapper';

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;

const StyledEmailIcon = styled(EmailIcon)`
  width: 20px;
  height: 20px;
  margin-right: 0;
  stroke-width: 1.5;
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
    &:disabled,
    &.disabled {
      stroke: #aebeca;
    }
  }
`;

const MediumSelectInput = styled(SelectInput)`
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      border-radius: 0.25rem;
      background-color: ${({ theme }) => theme.lozengeColor};
      padding: 0;
      padding-left: 5px;
    }
  }
`;

const EmailInviteBtn = styled(Button)`
  box-sizing: border-box;
  height: 40px;
  &:disabled,
  &.disabled {
    height: 40px;
    cursor: not-allowed;
    background: #fff;
    color: #aebeca;
    border-color: #aebeca;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
      & path {
        transition: all 0.3s;
        stroke: #fff;
      }
    }
    & path {
      stroke: #aebeca;
    }
  }
`;

const CustomFormTitle = styled(FormTitle)`
  font-size: 1.5rem;
`;

export const FormDescription = styled.p`
  font-size: 14px;
  line-height: 1.33;
  color: #324759;
  margin-bottom: 1.2rem;
`;

export type SortOrder = 'ASC' | 'DESC';
export type SortingType = 'RATING' | 'NAME' | 'TYPE';

type RecruiterManagementBoardProps = {
  selectedJob: string | null;
  selectedJobSpecialization?: { id: string; name: string } | null;
  fromJobCreation: boolean;
  onComplete: () => void;
  onConfirm?: (publish: boolean) => Promise<void>;
}

const RecruiterManagementBoardNative = ({
  selectedJob,
  fromJobCreation,
  onComplete,
  selectedJobSpecialization,
  onConfirm,
}: RecruiterManagementBoardProps) => {
  const loader = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState<any[]>([]);
  const [allDomains, setAllDomains] = useState<Specialization[]>([]);
  const [isAddRecruiterFormVisible, setIsAddRecruiterFormVisible] =
    useState<boolean>(false);
  const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
  const [next, setNext] = useState<string | null>(null);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [allSelectedInThisSession, setAllSelectedInThisSession] =
    useState<boolean>(false);
  const [recruitersSelectedInThisSession, setRecruitersSelectedInThisSession] =
    useState<string[]>([]);
  const [
    totalNumberOfAvailableRecruiters,
    setTotalNumberOfAvailableRecruiters,
  ] = useState<number>(0);
  const [totalNumberOfSelectedRecruiters, setTotalNumberOfSelectedRecruiters] =
    useState<number>(0);

  const [t] = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const companyName = user?.company?.name;
  let [currentSortingType, setCurrentSortingType] =
    useState<SortingType>('TYPE');
  let [currentSortingOrder, setCurrentSortingOrder] =
    useState<SortOrder>('DESC');
  let [sortQueryString, setSortQueryString] = useState('-own_recruiter');

  const setRecruiterIsSelectedField = (
    rec: Recruiter,
    new_is_selected_val: boolean,
  ) => {
    let updatedRecs = [...recruiters];

    const indexToUpdate = updatedRecs.findIndex(
      (recruiter) => recruiter.id === rec.id,
    );
    // Create a copy of the recruiter object you want to update
    const updatedRecruiter = { ...updatedRecs[indexToUpdate] };

    // Update the desired field of the recruiter object
    updatedRecruiter.is_selected = new_is_selected_val;
    if (new_is_selected_val) {
      setRecruitersSelectedInThisSession((prevState) => [
        ...prevState,
        updatedRecruiter.id,
      ]);
    } else {
      setRecruitersSelectedInThisSession([
        ...recruitersSelectedInThisSession.filter(
          (rec: string) => rec !== updatedRecruiter.id,
        ),
      ]);
    }

    // Replace the old recruiter object with the updated one
    updatedRecs[indexToUpdate] = updatedRecruiter;

    if (new_is_selected_val) {
      setTotalNumberOfSelectedRecruiters((prevState) => prevState + 1);
    } else {
      setTotalNumberOfSelectedRecruiters((prevState) => prevState - 1);
    }

    // Update the state
    setRecruiters(updatedRecs);
  };

  const handleSelectAll = (isChecked: boolean) => {
    setAllSelected(isChecked);

    if (!isChecked) {
      setRecruitersSelectedInThisSession([]);
    }
    if (isChecked && !allSelectedInThisSession) {
      setAllSelectedInThisSession(true);
    }
    if (isChecked) {
      setTotalNumberOfSelectedRecruiters(totalNumberOfAvailableRecruiters);
    } else {
      setTotalNumberOfSelectedRecruiters(0);
    }
    setRecruiters(
      recruiters.map((rec) => {
        rec.is_selected = isChecked;
        return rec;
      }),
    );
  };

  useEffect(() => {
    setLoading(true);
    getSpecializationList().then((res) => {
      setAllDomains(
        res.data.map((x: any) => ({
          ...x,
          color: RecruiterManagementTagColors[Math.floor(Math.random() * RecruiterManagementTagColors.length)],
        })),
      );
      setDomains(structuredDomains(res.data));
    });
    getRecrutersSelectedForJob({
      query: `${companyName}-${selectedJob}&sort=${sortQueryString}`,
      job_id: selectedJob,
    }).then((res: any) => {
      setTotalNumberOfSelectedRecruiters(
        res.data.results?.[0]?.total_previously_selected_recs,
      );
      setRecruiters(res.data.results);
      setNext(res.data.pagination.next);
      setTotalNumberOfAvailableRecruiters(res.data.pagination.count);
    });
    getPreviouslySelectedRecruiters(`${companyName}-${selectedJob}`).then(
      (res: any) => {
        setRecruitersSelectedInThisSession(res.data.results);
      },
    );
    setLoading(false);
  }, [companyName, selectedJob, sortQueryString]);

  useEffect(() => {
    switch (currentSortingType) {
      case 'NAME':
        if (currentSortingOrder === 'ASC')
          setSortQueryString('user__first_name');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('-user__first_name');
        break;
      case 'TYPE':
        if (currentSortingOrder === 'ASC') setSortQueryString('own_recruiter');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('-own_recruiter');
        break;
      case 'RATING':
        if (currentSortingOrder === 'ASC')
          setSortQueryString('-overall_rating');
        if (currentSortingOrder === 'DESC')
          setSortQueryString('overall_rating');
        break;
    }
    // eslint-disable-next-line
  }, [currentSortingType, currentSortingOrder]);

  let onFilter = (_: any, allValues: any) => {
    let querySearch: any[] = [];

    allValues.forEach((elem: any) => {
      if (elem['name'][0] === 'rating' || elem['name'][0] === 'category')
        return;
      querySearch.push({ param: elem['name'][0], value: elem.value });
    });

    let queryString = querySearch
      .filter((elem) => elem.value !== undefined)
      .map((elem) => {
        if (Array.isArray(elem.value)) {
          elem.value = elem.value.join(',');
          return elem;
        }
        return elem;
      })
      .map((elem) => `${elem.param}=${elem.value}`)
      .join('&');

    queryString = queryString + `&sort=${sortQueryString}`;
    setLoading(true);
    getRecrutersSelectedForJob({
      query: `${companyName}-${selectedJob}&${queryString}`,
      job_id: selectedJob,
    }).then((res) => {
      let newRecs = res.data.results;
      if (allSelected) {
        newRecs = newRecs.map((rec: Recruiter) => {
          rec.is_selected = true;
          return rec;
        });
      }
      if (allSelectedInThisSession && !allSelected) {
        newRecs = newRecs.map((rec: Recruiter) => {
          rec.is_selected = false;
          return rec;
        });
      }

      setRecruiters(
        newRecs.map((rec: Recruiter) => {
          if (recruitersSelectedInThisSession.includes(rec.id)) {
            rec.is_selected = true;
            return rec;
          }
          return rec;
        }),
      );
      setNext(res.data.pagination.next);
    });
    setLoading(false);
  };

  onFilter = debounce(onFilter, 300, { trailing: true });

  const handleTableScroll = (e: any) => {
    if (next === null || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;

    // Check if the scroll position is at the bottom
    // -1 fixed a bug not sure why, don't touch unless very neccessery
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setLoading(true);
      getRecrutersSelectedForJob({
        query: `${companyName}-${selectedJob}&cursor=${next}&sort=${sortQueryString}`,
        job_id: selectedJob,
      })
        .then((res) => {
          let newRecs = res.data.results;
          if (allSelected) {
            newRecs = newRecs.map((rec: Recruiter) => {
              rec.is_selected = true;
              return rec;
            });
          }
          if (allSelectedInThisSession && !allSelected) {
            newRecs = newRecs.map((rec: Recruiter) => {
              rec.is_selected = false;
              return rec;
            });
          }
          if (recruitersSelectedInThisSession.length) {
            newRecs = newRecs.map((rec: Recruiter) => {
              if (recruitersSelectedInThisSession.includes(rec.id)) {
                rec.is_selected = true;
                return rec;
              }
              return rec;
            });
          }
          setRecruiters((prevState: Recruiter[]) => [...prevState, ...newRecs]);
          setNext(res.data.pagination.next);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleInviteRecruiterBtnClick = () => {
    setIsAddRecruiterFormVisible(true);
  };

  return (
    <>
      <Form
        scrollToFirstError={true}
        layout="horizontal"
        onFieldsChange={onFilter}
      >
        <CustomFormTitle text="Select talent suppliers" marginBottom="0.2em" />
        <Row justify="start" align="middle" gutter={10}>
          <Col>
            <FormItem name="full_name">
              <NewSearchInput placeholder="Full name" />
            </FormItem>
          </Col>
          <Col>
            <FormItem name="company_name">
              <NewSearchInput placeholder="Company name" />
            </FormItem>
          </Col>
          <Col>
            <FormItem>
              <EmailInviteBtn
                size="middle"
                onClick={handleInviteRecruiterBtnClick}
                disabled={isAddRecruiterFormVisible}
              >
                <StyledEmailIcon />
                Invite talent supplier
              </EmailInviteBtn>
            </FormItem>
          </Col>
        </Row>
        <AddCompanyRecruiterForm
          isVisible={isAddRecruiterFormVisible}
          setIsVisible={setIsAddRecruiterFormVisible}
        />
        <Row justify="start" gutter={10}>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="recommended_badge">
              <SelectInput
                showSearch
                placeholder="Filter by category"
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
                allowClear
              >
                <Select.Option value="Recommended">
                  Recommended recruiters
                </Select.Option>
              </SelectInput>
            </FormItem>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="domain">
              <MediumSelectInput
                showSearch
                placeholder="Specialisations"
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
                mode="multiple"
                allowClear
              >
                {domains.map((dom) => (
                  <Select.Option value={dom.value} key={dom.value}>
                    {dom.title}
                  </Select.Option>
                ))}
              </MediumSelectInput>
            </FormItem>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="ratings">
              <SelectInput
                showSearch
                placeholder="All Ratings"
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Select.Option value="">{t('NONE')}</Select.Option>
                {RATING_FILTERS.map((ratingFilter, index) => (
                  <Select.Option value={ratingFilter.value} key={index}>
                    {ratingFilter.name}
                  </Select.Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Loader spinning={loading}>
        {!loading && recruiters.length === 0 ? (
          <NoResultText>{t('NO_RESULT')}</NoResultText>
        ) : (
          <RecruiterSelectTable
            recruiters={recruiters}
            domains={allDomains}
            selectedJobSpecialization={selectedJobSpecialization}
            setRecruiterIsSelectedField={setRecruiterIsSelectedField}
            handleTableScroll={handleTableScroll}
            handleSelectAll={handleSelectAll}
            setSortType={setCurrentSortingType}
            setSortOrder={setCurrentSortingOrder}
            currentSortOrder={currentSortingOrder}
            currentSortType={currentSortingType}
          />
        )}
        <div className="loader" ref={loader} />
      </Loader>
      <RecruiterManagementBoardFooter
        onComplete={onComplete}
        fromJobCreation={fromJobCreation}
        companyName={companyName}
        selectedJob={selectedJob}
        allRows={recruiters.map((rec) => rec.id)}
        selectedRows={recruiters
          .filter((rec) => rec.is_selected)
          .map((rec) => rec.id)}
        recruitersSelectedInThisSession={recruitersSelectedInThisSession}
        highThreshold={35}
        numOfSelectedRecrutiers={totalNumberOfSelectedRecruiters}
        allSelected={allSelected}
        allSelectedInThisSession={allSelectedInThisSession}
        onConfirm={onConfirm}
      />
    </>
  );
}

export const RecruiterManagementBoard = withTestUIWrapper(RecruiterManagementBoardNative, ({ selectedJob }) => ({
  testKey: TEST_UI_KEYS.SelectTalentSuppliers,
  params: {
    jobId: selectedJob,
  },
}));

type RecruiterManagementBoardModalProps = {
  isVisible: boolean;
  onComplete: () => void;
  selectedJob: string | null;
  fromJobCreation: boolean;
  selectedJobSpecialization?: { id: string; name: string } | null;
}

export function RecruiterManagementBoardModal({
  isVisible,
  onComplete,
  selectedJob,
  fromJobCreation,
  selectedJobSpecialization,
}: RecruiterManagementBoardModalProps) {

  return (
    <StyledModal
      style={{ top: '20px' }}
      destroyOnClose={true}
      footer={null}
      visible={isVisible}
      width={1100}
      onCancel={() => onComplete()}
    >
      <RecruiterManagementBoard
        onComplete={onComplete}
        fromJobCreation={fromJobCreation}
        selectedJob={selectedJob}
        selectedJobSpecialization={selectedJobSpecialization}
      />
    </StyledModal>
  );
}

export default RecruiterManagementBoard;
