import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
  getAdminContacts,
  getClientContacts,
  getRecruiterContacts,
} from '../../api/messages';
import hireportLogoIcon from '../../icons/logo-ufo.svg';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import { Contact } from '../../types';
import { getRandomColor } from '../../constants/palette';
import { MESSAGES } from '../../constants/routes';
import {
  getSupportUnread,
  resetContactCounter,
} from '../../store/messagesSlice';
import Loader from '../../UI/Loader';
import ChatMessages, { ContactItem, ContactItemInfo, ContactItemTitle, UnreadMessagesBadge } from './ChatMessages';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

const ContactsList = styled.ul`
  list-style: none;
  width: 17rem;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0.5rem 0;
 @media (max-width: 768px) {
  width: 100%;
  }
`;

const HireportLogo = styled.span`
  width: 53px;
  height: 40px;
  background-image: ${() => "url('" + hireportLogoIcon + "')"};
  background-position: center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #dae5ec;
`;

const ContactListHeader = styled.div`
  padding: 1rem 0;
  margin-top: 1rem;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  border-bottom: 1px solid #dae5ec;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ContactListWrapper = styled.div`
    @media (max-width: 768px) {
  &.hide-mobile {
      display: none;
    }
    width: 100vw;
  }
`;

interface ContactListProps extends React.HTMLAttributes<HTMLDivElement> {
  onSelectContact: (contact: Contact) => void;
  searchQuery: string;
  onLoaded: () => void;
}

const ContactList = (props: ContactListProps) => {
  const { searchQuery, onSelectContact, onLoaded, ...rest } = props;
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [nextPage, setNextPage] = useState(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistoryWithUrlBase();
  const loader = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { contactId } = useParams<{ contactId: string }>();
  const { supportUnread } = useAppSelector(
    (state) => state.messages,
  );
  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const handleSelectContact = useCallback(
    (contact: Contact) => {
      onSelectContact(contact);
      setSelectedContact(contact);
      history.push(MESSAGES + '/' + contact.id);
      if (isAdmin) {
        dispatch(resetContactCounter(contact.id));
      }
    },
    [isAdmin, dispatch, history, onSelectContact],
  );

  const getContacts = useCallback(async () => {
    setLoading(true);
    let result = null;
    let query = '';
    if (searchQuery) {
      query = '?candidate_name=' + searchQuery;
    }
    if (isAdmin) {
      result = await getAdminContacts();
    }
    if (isClient) {
      result = await getClientContacts(query);
    }
    if (isRecruiter) {
      result = await getRecruiterContacts(query);
    }
    if (isClient || isRecruiter) {
      dispatch(getSupportUnread());
    }
    if (result && result.data) {
      setContacts(
        result.data.results.map((c: Contact) => ({
          ...c,
          color: getRandomColor(),
        })),
      );
      if (query) {
        let firstContact = result.data.results[0];
        if (firstContact) {
          handleSelectContact(firstContact);
        }
      }
      setLoading(false);
      onLoaded();
      setNextPage(result.data.pagination.next);
    }
  }, [searchQuery, handleSelectContact, isAdmin, isClient, isRecruiter, onLoaded, dispatch]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  useEffect(() => {
    if (loading) {
      // prevent redirect to 404 on page refresh
      return;
    }

    if (contactId) {
      let contact = contacts.find((contact) => contact.id === contactId);
      if (contactId === 'support') {
        contact = {
          id: 'support',
          role: 'support',
          image: '',
          name: 'support',
          unread_message_cnt: supportUnread,
        };
      }

      if (contact) {
        onSelectContact(contact);
        setSelectedContact(contact);
      }
    }
  }, [contactId, contacts, onSelectContact, supportUnread, history, loading]);

  const handleObserver = useCallback(
    async (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + nextPage
        : '?cursor=' + nextPage
        }`;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        // @ts-ignore
        let result = null;
        if (isAdmin) {
          result = await getAdminContacts(cursor);
        }
        if (isClient) {
          result = await getClientContacts(cursor);
        }
        if (isRecruiter) {
          result = await getRecruiterContacts(cursor);
        }
        if (result && result.data) {
          setContacts((state) => [
            ...state,
            // @ts-ignore
            ...result.data.results.map((c: Contact) => ({
              ...c,
              color: getRandomColor(),
            })),
          ]);
          setNextPage(result.data.pagination.next);
        }
      }
    },
    [nextPage, history.location.search, isAdmin, isClient, isRecruiter],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [nextPage, handleObserver]);

  return (
    <Loader spinning={loading}>
      <ContactListWrapper {...rest}>
        {isClient && <ContactListHeader>{t('RECRUITERS')}</ContactListHeader>}
        {isRecruiter && <ContactListHeader>{t('COMPANIES')}</ContactListHeader>}
        <ContactsList>
          {(isClient || isRecruiter) && (
            <ContactItem
              key="support"
              onClick={() =>
                handleSelectContact({
                  id: 'support',
                  role: 'support',
                  image: '',
                  name: 'support',
                  unread_message_cnt: supportUnread,
                })
              }
              active={
                selectedContact ? selectedContact.id === 'support' || contactId === 'support' : false
              }
            >
              <HireportLogo />
              <ContactItemInfo>
                <ContactItemTitle>
                  {t(
                    isClient
                      ? 'CUSTOMER_SUCCESS_TEAM'
                      : 'HIREPORT_SUPPORT_TITLE',
                  )}
                </ContactItemTitle>
              </ContactItemInfo>
              {supportUnread > 0 && (
                <UnreadMessagesBadge
                  count={supportUnread}
                />
              )}
            </ContactItem>
          )}
          <ChatMessages contacts={contacts} showUnreadCount={!isAdmin} selectedContact={selectedContact} showRole={isAdmin} onItemClick={handleSelectContact} />
        </ContactsList>
        <div className="loader" ref={loader} />
      </ContactListWrapper>
    </Loader>
  );
};

export { ContactList };
