import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, RouteProps, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { getToken, storeToken } from '../../utils/localStorage';
import axios from 'axios';
import { getUserByToken } from '../../store/userSlice';
import Error from '../../pages/Error';
import { IS_ADMIN_KEY } from '../../constants/roles';
import { postMessage } from '../helper/browserPlugins';
import { defaultHireportTheme } from '../../hooks/useHirePortTheme';
import { HirePortContextProvider } from '../../hooks/useHirePortContext';
import useIntercomUpdate from '../../hooks/useIntercomUpdate';
import { PackName, themes } from '../../pages/browser-plugins/chromium/constants';

const LayoutBody = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 0;
  min-height: 100vh;
  &.mobile-body {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  &.onboard-route {
    padding: 0;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
`;

type BrowserPluginRouteProps = RouteProps & {
  pack: PackName;
  component: FunctionComponent;
}

/**
 * Renders a page with no layout, and which posts browser messages to help manage its state
 */
const BrowserPluginRoute = (props: BrowserPluginRouteProps) => {
  const { component: Component, pack, ...rest } = props;

  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const { isAuth, loading, user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const match = useRouteMatch();
  const location = history.location;

  const token = getToken('access_token');
  const isAdmin = getToken(IS_ADMIN_KEY);

  const { token: urlToken, ...queryParams } = Object.fromEntries(new URLSearchParams(location.search));

  useIntercomUpdate(user);

  useEffect(() => {
    if (urlToken) {
      storeToken(urlToken.toString());
      // remove the token parameter from the URL and redirect
      location.search = new URLSearchParams(queryParams).toString();
      history.replace(location);
      return;
    }

    axios.defaults.headers.common['Authorization'] = token
      ? `Token ${token}`
      : '';

    if (isAdmin) {
      // this header need only if admin login as customer
      axios.defaults.headers.common['X-Login-As-User'] = true;
    } else {
      delete axios.defaults.headers.common['X-Login-As-User'];
    }

    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403))
        postMessage({ event: 'AuthenticationError' });
      if (error.response.status < 500)
        return Promise.reject(error);
      setError(true)
    });

    if (token && !isAuth)
      dispatch(getUserByToken(token));

    if (!token && !isAuth)
      postMessage({ event: 'AuthenticationError' });

    // !WB: not sure what this is - disabling for now
    // if (user && user.role === 'decision_maker') {
    //   dispatch(logoutUser());
    // }
  }, [dispatch, isAuth, token, isAdmin, location, history, queryParams, urlToken]);

  return (
    <Route
      {...rest}
      render={(props) => (
        error ? <Error /> :
          loading || !isAuth ? null : (
            <HirePortContextProvider value={{
              theme: themes[pack] || defaultHireportTheme,
              urlBase: match.path,
              renderPageHeader: false,
              renderPageWrapper: false,
              renderCustom: {
                recruiterPublicProfile: {
                  hideCandidateList: true,
                },
                recruiterSelection: {
                  disableLinkToProfile: true,
                },
                jobEdit: {
                  hideProgress: true,
                },
              },
            }}>
              <LayoutBody>
                <Component {...props} />
              </LayoutBody>
            </HirePortContextProvider>
          )
      )
      }
    />
  );
};

export default BrowserPluginRoute;
