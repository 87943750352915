import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';

import Column from './Column';
import {
  CandidateInfo,
  CandidateStagesGroup,
  InterviewRoom,
  InterviewStage,
  InterviewTask,
  CandidatesStagesStatuses,
} from '../../../types';
import { groupByStages } from '../../../utils/candidate';
import InterviewStageModal from './InterviewStageModal';
import {
  getClientInterviewStages,
  getInterview,
  moveToNextTask,
} from '../../../api/interviews';
import InterviewDateProposeModal from './InterviewDateProposeModal';
import FeedbackModal from './FeedbackModal';
import axios from 'axios';
import CandidateOfferModal from '../CandidateOfferModal';
import { ReactComponent as EmptyIcon } from '../../../icons/empty.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loader from '../../../UI/Loader';
import { COMPLETED, INTRODUCED, REJECTED } from '../../../constants/statuses';
import { useAppSelector } from '../../../store/hooks';
import {
  CLIENT_ROLE,
  ADMIN_ROLE,
  RECRUITER_ROLE,
} from '../../../constants/roles';
import { getJobsInterviewStages } from '../../../api/interview-stages';
import ConfirmModal from './ConfirmModal';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_COMPANY_CHAT,
} from '../../../constants/queryParams';
import { HIRED } from '../../../constants/statuses';
import CompanyChatModal from './CompanyChatModal';
import { getCandidatesStagesStatuses } from '../../../api/candidates';
import MoveInAtsModal from './MoveInAtsModal';

interface KanbanProps {
  candidates: CandidateInfo[];
  setInfo: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
  collect: (info: CandidateInfo[]) => CandidateInfo[];
}

interface KeyString {
  [key: string]: boolean;
}
interface jobStagesProps {
  job_id: string;
  stages: {
    id: string;
    name: string;
    order: number;
  }[];
}

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 0.875rem;
    line-height: 1.43;
    text-align: center;
    color: #aebeca;
    margin: 0;
  }
  svg {
    margin: 0 auto 1rem;
    display: block;
  }
`;

const Kanban = ({ candidates, setInfo, collect }: KanbanProps) => {
  const { user } = useAppSelector((state) => state.user);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState<InterviewStage[] | null>(null);
  const [jobStages, setJobStages] = useState<jobStagesProps[]>([]);
  const [disabled, setDisabled] = useState<KeyString>({});
  const [interview, setInterview] = useState<InterviewRoom | null>(null);
  const [candidateStagesStatuses, setCandidateStagesStatuses] = useState<
    CandidatesStagesStatuses[]
  >([]);

  const [nextStageData, setNextStageData] = useState<{
    interviewId: string;
    candidateID: string;
    stage_name?: string;
    from: string;
    stage_id?: string;
    reason_for_change?: string;
    is_rejection?: boolean;
  } | null>(null);

  const [candidateStages, setCandidateStages] =
    useState<CandidateStagesGroup | null>(null);

  const [showInterviewStageModal, setShowInterviewStageModal] = useState(false);
  const [showCompanyChatModal, setShowCompanyChatModal] = useState(false);

  const [t] = useTranslation();
  const history = useHistory();
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const params = new URLSearchParams(history.location.search);

  const interview_id = params.get(INTERVIEW_ID_PARAM);
  const stage = params.get(STAGE_PARAM); // use this for the current stage
  const show_date_propose = params.get(SHOW_DATE_PROPOSE_PARAM);
  const feedback = params.get(SHOW_FEEDBACK_PARAM);
  const chat = params.get(SHOW_COMPANY_CHAT);

  /* get, sort and set stages start */
  useEffect(() => {
    let source = axios.CancelToken.source();
    getClientInterviewStages().then((res) => {
      let sorted = res.data.sort(
        (a: InterviewStage, b: InterviewStage) => a.order - b.order,
      );
      setStages(sorted);
      const names = sorted.map((s: InterviewStage) => s.name.toLowerCase());
      let keys: KeyString = { introduced: false };
      names.forEach((key: string) => (keys[key] = false));
      keys['offered'] = false;
      keys['hired'] = false;
      keys['on hold'] = false;
      setDisabled(keys);
    });
    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (stages) {
      let obj: CandidateStagesGroup = {};
      stages.forEach((stage) => (obj[stage.name.toLowerCase()] = []));
      groupByStages({
        candidates,
        stages: obj,
        showReject: user?.role === CLIENT_ROLE,
        showHired: user?.role !== ADMIN_ROLE,
      }).then((res) => {
        if (user?.role === ADMIN_ROLE) {
          delete res.hired; // clear up default empty hired array
        }
        setCandidateStages(res);
        setLoading(false);
      });
    }
    return () => setLoading(true);
  }, [candidates, stages, user?.role]);
  /* get, sort and set stages end */

  useEffect(() => {
    const getInterviewById = async (id: string) => {
      const response = await getInterview(id, history);
      return response.data;
    };

    if (candidateStages) {
      if (interview_id) {
        getInterviewById(interview_id).then((res) => {
          const mainInterview = res.current_stage?.tasks?.find(
            (task: InterviewTask) => task.is_planning_task,
          );

          if (mainInterview?.status === COMPLETED) {
            setInterview({
              ...res,
              next_stage: stage
                ? candidateStages[stage]?.find(
                    (el) => el.interviews[0].id === interview_id,
                  )?.interviews[0].next_stage
                : null,
            });
          } else {
            setInterview(res);
          }
          if (stage) {
            setShowInterviewStageModal(true);
          }
          if (chat) {
            setShowCompanyChatModal(true);
          }
        });
      } else {
        setInterview(null);
      }
    } else {
      setInterview(null);
    }
  }, [candidateStages, interview_id, stage, history, chat]);

  useEffect(() => {
    if (!candidates.length) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }, [candidates]);

  useEffect(() => {
    if (isClient || isRecruiter)
      getJobsInterviewStages().then((res) => setJobStages(res.data));
  }, [isClient, isRecruiter]);

  useEffect(() => {
    getCandidatesStagesStatuses().then((res) =>
      setCandidateStagesStatuses(res.data.results),
    );
  }, []);

  const disableField = (current: string = '', jobId?: string) => {
    const allStages = Object.keys(disabled);
    let disabledFields: KeyString = {};
    if (current) {
      const allowedStages = jobStages.find((el) => el.job_id === jobId);
      const sortedStages = allowedStages?.stages.sort(
        (a, b) => a.order - b.order,
      );

      const stageNames = sortedStages?.map((st) => st.name) || [];

      let isPassed = false;

      allStages.forEach((stage) => {
        if (current === stage) isPassed = true;
        if (current === 'rejected') {
          disabledFields[stage] = stage === 'introduced';
        } else if (stage === 'on hold') {
          allStages.forEach((stage) => {
            disabledFields[stage] = false;
          });
        } else {
          disabledFields[stage] = !isPassed;
        }
        if (current !== INTRODUCED) {
          disabledFields[INTRODUCED] = true;
        }
      });

      stages?.forEach((sn) => {
        if (!stageNames?.find((s) => s === sn.name)) {
          disabledFields[sn.name.toLowerCase()] = true;
        }
      });

      if (isRecruiter) {
        // disable hired column for recruiter
        disabledFields[HIRED] = true;
      }
    } else {
      allStages.forEach((stage) => {
        disabledFields[stage] = false;
      });
    }

    setDisabled(disabledFields);
  };

  const onDragEnd = (result: any) => {
    if (result?.destination) {
      const dropColumn = result?.destination?.droppableId;
      const isSame = dropColumn === result?.source?.droppableId;

      if (candidateStages && !isSame) {
        const dragColumn = result.source.droppableId.toLowerCase();
        const dragIndex = result.source.index;
        const hasUncompletedTasks = !!candidateStages[dragColumn][
          dragIndex
        ].interviews[0].current_stage?.tasks?.filter(
          (el) => el.status !== 'completed',
        ).length;
        const isRejection = dropColumn === 'rejected';
        const dynamicStage = stages?.find(
          (el) => el.name.toLowerCase() === dropColumn,
        );
        const dynamicStageId = dynamicStage?.id;
        const dragItem = candidateStages[dragColumn][dragIndex];
        if (isRejection) {
          setNextStageData({
            interviewId: dragItem.interviews[0].id,
            candidateID: dragItem.id,
            stage_name: dropColumn,
            reason_for_change: '',
            from: dragColumn,
            stage_id: dynamicStageId,
            is_rejection: true,
          });
        } else if (hasUncompletedTasks) {
          setNextStageData({
            interviewId: dragItem.interviews[0].id,
            candidateID: dragItem.id,
            stage_name: dropColumn,
            from: dragColumn,
            stage_id: dynamicStageId,
          });
        } else {
          moveTo(
            dragItem?.interviews[0].id,
            dragItem?.id,
            dropColumn,
            dragColumn,
          );
        }
      }
    }
    disableField();
  };

  const onDragStart = (result: any) => {
    console.log('start drag', result);
    const column = result.source.droppableId.toLowerCase();
    const idx = result.source.index;
    const jobId =
      (candidateStages && candidateStages[column][idx].interviews[0].job.id) ||
      '';
    disableField(column, jobId);
  };

  const updateInterview = (
    interviewId: string,
    stageName: string,
    candidateID: string,
  ) => {
    getInterview(interviewId, history).then((res) => {
      candidateStages &&
        setCandidateStages((prevState) => {
          if (prevState) {
            const keys = Object.keys(prevState);
            let update: { [key: string]: CandidateInfo[] } = {};
            keys.forEach((el) => {
              if (el === stageName) {
                update[el] = prevState[stageName].map((el) => {
                  if (el.interviews[0].id === interviewId) {
                    let interview = el.interviews;
                    interview[0] = { ...interview[0], ...res.data };
                    return { ...el, interviews: [...interview] };
                  }
                  return el;
                });
              } else {
                update[el] = prevState[el];
              }
            });
            return update;
          } else {
            return null;
          }
        });
    });
  };

  const removeItem = (
    candidateID: string,
    currentStage: string,
    interviewId: string,
  ) => {
    if (candidateStages) {
      setCandidateStages((prevState) => ({
        ...prevState,
        [currentStage]: prevState
          ? prevState[currentStage].filter(
              (el) =>
                el.id !== candidateID && el.interviews[0].id !== interviewId,
            )
          : [],
      }));
    }
  };

  const moveTo = async (
    interviewId: string,
    candidateId: string,
    nextStageNameRaw: string,
    currentStageNameRaw: string,
    reason?: string,
  ) => {
    const nextStageName = nextStageNameRaw.toLowerCase();
    const currentStageName = currentStageNameRaw.toLowerCase();

    const nextStage = stages?.find(
      (el) => el.name.toLowerCase() === nextStageName,
    );
    const nextStageId = nextStage?.id;

    await moveToNextTask(interviewId, nextStageName, nextStageId, reason).then(
      (_) => {
        getInterview(interviewId, history).then((response) => {
          if (candidateStages) {
            const target = candidateStages[currentStageName]?.find(
              (card) =>
                card.id === candidateId &&
                card.interviews[0].id === interviewId,
            );

            if (target) {
              // @ts-ignore
              setCandidateStages((prevState) => {
                if (!prevState) return null;

                // if the target stage doesn't exist here - probably because we're a recruiter and are moving to rejected
                if (!prevState[nextStageName])
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].filter(
                    // just remove the record
                      (card) => card.interviews[0].id !== interviewId,
                    ),
                  };

                // if we are a client user, temporarily mask the blocked flag since it should be corrected soon by the ATS processing tasks
                const newInterview = isClient
                  ? { ...response.data, is_blocked: false }
                  : response.data;

                const newCard = {
                  ...target,
                  interviews: [newInterview, ...target.interviews.slice(1)],
                };
                if (currentStageName === nextStageName) {
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].map(
                      (card) =>
                        card.interviews[0].id === interviewId ? newCard : card,
                    ),
                  };
                } else {
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].filter(
                      (card) => card.interviews[0].id !== interviewId,
                    ),
                    [nextStageName]: [newCard, ...prevState[nextStageName]],
                  };
                }
              });
            }
          }
          setNextStageData(null);

          if (stage) {
            const query = new URLSearchParams(history.location.search);
            query.set(STAGE_PARAM, nextStageName);
            history.push({ search: query.toString() });
          }

          if (nextStageName === REJECTED) {
            // close all modals
            history.push({ search: '?filter=in-process' });
          }
        });
      },
    );
  };

  const moveToNextStage = (
    interviewId: string,
    candidateId: string,
    nextStageNameRaw: string,
    currentStageNameRaw: string,
    hasUncompletedTasks: boolean,
  ) => {
    const nextStageName = nextStageNameRaw.toLowerCase();
    const currentStageName = currentStageNameRaw.toLowerCase();

    const nextStage = stages?.find(
      (el) => el.name.toLowerCase() === nextStageName,
    );

    const nextStageId = nextStage?.id;

    if (hasUncompletedTasks) {
      setNextStageData({
        interviewId: interviewId,
        candidateID: candidateId,
        stage_name: nextStageName,
        from: currentStageName,
        stage_id: nextStageId,
      });
    } else {
      moveTo(interviewId, candidateId, nextStageName, currentStageName);
    }
  };

  const [moveInAtsModalState, setMoveInAtsModalState] =
    useState<CandidateInfo | null>(null);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {loading ? (
        <Loader spinning={loading}></Loader>
      ) : (
        <Wrapper>
          {candidateStages &&
            Object.keys(candidateStages).map((group) => (
              <Column
                title={group}
                data={candidateStages[group]}
                key={group}
                disabled={disabled[group]}
                candidateStagesStatuses={candidateStagesStatuses}
                onOffSyncClicked={(info) => {
                  isClient &&
                    !info.interviews?.[0].ats_error &&
                    setMoveInAtsModalState(info);
                }}
              />
            ))}

          {empty && (
            <Empty>
              <EmptyIcon />
              <p>{t('NO_CANDIDATE_YET')}</p>
            </Empty>
          )}
        </Wrapper>
      )}

      <InterviewStageModal
        interview={interview || {}}
        visible={showInterviewStageModal && !!interview_id && !!stage}
        setShowInterviewStageModal={setShowInterviewStageModal}
        handleSwitchStage={moveToNextStage}
        handleEndProcess={setNextStageData}
        currentStage={stage || ''}
      />

      <CompanyChatModal
        interview={interview || {}}
        visible={showCompanyChatModal && !!interview_id}
        setShowCompanyChatModal={setShowCompanyChatModal}
      />

      {show_date_propose && interview && (
        <InterviewDateProposeModal
          info={interview}
          onComplete={updateInterview}
        />
      )}

      {feedback && interview && (
        <FeedbackModal
          info={interview}
          onComplete={updateInterview}
          removeItem={removeItem}
          setShowInterviewStageModal={setShowInterviewStageModal}
        />
      )}

      <CandidateOfferModal
        collect={collect}
        onSuccess={updateInterview}
        removeItem={removeItem}
        setShowInterviewStageModal={setShowInterviewStageModal}
      />

      <ConfirmModal
        stage={nextStageData}
        setStage={setNextStageData}
        move={moveTo}
        currentStage={stage || undefined}
      />

      <MoveInAtsModal
        state={moveInAtsModalState}
        onCancel={() => {
          setMoveInAtsModalState(null);
        }}
        onConfirm={async (info) => {
          const interview = info.interviews[0];
          await moveTo(
            interview.id,
            info.id,
            interview.current_stage.stage_name,
            interview.current_stage.stage_name,
          );
          setMoveInAtsModalState(null);
        }}
      />
    </DragDropContext>
  );
};

export default Kanban;
