import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGa from 'react-ga4';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CandidateInfo,
  Certificate,
  City,
  Job,
  Language,
  Role,
  Seniority,
  Skill,
  Specialization,
  Education,
  JobActivity,
} from '../../types';
import { useAppSelector } from '../../store/hooks';
import { getRolesList } from '../../api/roles';
import { getCityList } from '../../api/regions';
import { getSkillsList } from '../../api/skills';
import { getSpecializationList } from '../../api/specialization';
import { getSeniorityList } from '../../api/seniority';
import { getCertificatesList } from '../../api/certificates';
import { getEducationList } from '../../api/education';
import { getAllInfo } from '../../utils/candidate';
import { getCandidate } from '../../api/candidates';
import { getLanguagesList } from '../../api/languages';
import { trackJobSectionEvent } from '../../api/events';
import { getJob, getJobActivityFeed, getPublicJob } from '../../api/jobs';
import { RECRUITER_ROLE } from '../../constants/roles';

const useSingleJob = (isPublic: boolean) => {
  const [t] = useTranslation();

  const [job, setJob] = useState<null | Job>(null);
  const [introducedList, setIntroducedList] = useState<string[]>([]);

  const [data, setData] = useState<{
    seniority: Seniority[];
    certificates: Certificate[];
    education: Education[];
    cities: City[];
    domains: Specialization[];
    skills: Skill[];
  }>({
    seniority: [],
    certificates: [],
    education: [],
    cities: [],
    domains: [],
    skills: [],
  });

  const [selectedCandidate, setSelectedCandidate] =
    useState<null | CandidateInfo>(null);

  let tree: string[][] = [];
  const history = useHistory();
  let str = typeof job?.career_path === 'string' ? job?.career_path : '[[],[]]';
  const careerPath: string[][] = JSON.parse(str);

  careerPath?.forEach((el) => {
    el.length && tree.push(el);
  });

  const params = new URLSearchParams(history.location.search);
  const editModal = params.get('edit');
  const [addCandidate, setAddCandidate] = useState<boolean>(!!editModal);
  const [addedCandidate, setAddedCandidate] = useState<null | CandidateInfo>(
    null,
  );
  const [activityFeed, setActivityFeed] = useState<null | JobActivity[]>(null);
  const [startIntroduction, setStartIntroduction] = useState<boolean>(false);
  const [languages, setLanguages] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<[] | Role[]>([]);

  const { id, candidate } = useParams<{ id: string; candidate?: string }>();
  const { user } = useAppSelector((state) => state.user);
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const editCandidate = (newCandidate: CandidateInfo) => {
    setAddedCandidate(
      getAllInfo({
        candidates: [newCandidate],
        cities: data.cities,
        skills: data.skills,
        roles,
        domains: data.domains,
        certificates: data.certificates,
        education: data.education,
      })[0],
    );
  };

  const addCandidateItem = (newCandidate: CandidateInfo) => {
    setAddedCandidate(
      getAllInfo({
        candidates: [newCandidate],
        cities: data.cities,
        skills: data.skills,
        roles,
        domains: data.domains,
        certificates: data.certificates,
        education: data.education,
      })[0],
    );
  };

  useEffect(() => {
    const query = [
      isPublic ? getPublicJob(id, history) : getJob(id, history),
      getRolesList(),
    ];
    console.log({ isPublic });
    axios.all(query).then((res) => {
      setJob(res[0].data);
      setRoles(res[1].data);
      setLoading(false);
    });
  }, [id, isPublic, candidate, history]);

  useEffect(() => {
    getLanguagesList().then((res) => {
      let list: { [key: string]: string } = {};
      res.data.forEach((lng: Language) => {
        list[lng.code] = lng.name;
      });
      setLanguages(list);
    });
  }, []);

  useEffect(() => {
    if (job?.id && user) {
      getJobActivityFeed(job.id).then((res) => {
        let feed = res.data;
        setActivityFeed(feed);
      });
    }
  }, [job, user]);

  useEffect(() => {
    if (isRecruiter || candidate) {
      let query = [];
      if (candidate) {
        query.push(getCandidate(candidate));
      }
      if (isRecruiter) {
        query.push(
          getCityList(),
          getSkillsList(),
          getSpecializationList(),
          getSeniorityList(),
          getCertificatesList(),
          getEducationList(),
        );
      } else {
        query = [];
      }
      axios.all(query).then((res) => {
        if (candidate) {
          const current = getAllInfo({
            candidates: [res[0].data],
            cities: res[1].data,
            skills: res[2].data,
            roles,
            domains: res[3].data,
            certificates: res[5].data,
            education: res[6].data,
          });

          setData({
            cities: res[1].data,
            skills: res[2].data,
            domains: res[3].data,
            seniority: res[4].data,
            certificates: res[5].data,
            education: res[6].data,
          });

          setSelectedCandidate(current.length ? current[0] : null);
        } else {
          setData({
            cities: res[0].data,
            skills: res[1].data,
            domains: res[2].data,
            seniority: res[3].data,
            certificates: res[4].data,
            education: res[5].data,
          });
          setLoading(false);
        }
      });
    }
  }, [isRecruiter, candidate, roles]);

  useEffect(() => {
    ReactGa.pageview('/job');
  }, []);

  const trackSection = (feedback: number, name?: string) => {
    trackJobSectionEvent(feedback, job?.id, name).then((res) => {
      if (job) {
        // @ts-ignore
        setJob((prevState) => ({
          ...prevState,
          job_feedbacks: [...job.job_feedbacks, res.data],
        }));
        message.success(t('THANKS_FEEDBACK_TEXT'), 5);
      }
    });
  };

  return {
    actions: {
      trackSection,
      addCandidateItem,
      editCandidate,
      setIntroducedList,
      setAddCandidate,
      setStartIntroduction,
      setJob,
      setSelectedCandidate,
    },
    data: {
      job,
      data,
      introducedList,
      addCandidate,
      addedCandidate,
      startIntroduction,
      languages,
      activityFeed,
      loading,
      roles,
      user,
      selectedCandidate,
      tree,
      id,
      editModal,
      isRecruiter,
    },
  };
};

export default useSingleJob;
